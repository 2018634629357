<template>
  <div id="app">
    <div class="wrapper">
      <SideNav v-if="this.$route.name != 'Login'" />
      <div class="main">
        <HeaderNav v-if="this.$route.name != 'Login'" />
        <main class="content">
          <router-view></router-view>
        </main>

        <footer class="footer" v-if="this.$route.name != 'Login'">
          <div class="container-fluid">
            <div class="row text-muted">
              <div class="col-6 text-start">
                <p class="mb-0">
                  <a class="text-muted"><strong>Developed By</strong></a> -
                  <a
                    class="text-muted"
                    href="https://www.linkedin.com/in/mohammed-fakhraden-b01846206"
                    target="_blank"
                    ><strong>Mohammed K. Fakhraden</strong></a
                  >
                  &copy;
                  <a
                    class="text-muted"
                    href="https://www.linkedin.com/in/mohammed-fakhraden-b01846206"
                    target="_blank"
                    ><strong>+964 771 579 0066</strong></a
                  >
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from "./components/HeaderNav.vue";
import SideNav from "./components/SideNav.vue";
import $ from "jquery";
export default {
  name: "App",
  components: {
    HeaderNav,
    SideNav,
  },
  watch: {
    $route() {
      $(".js-sidebar").removeClass("collapsed");
    },
  },
  mounted() {
    const storedLang = localStorage.getItem("selectedLang");
    console.log("storedlang ", storedLang);
    if (storedLang) {
      this.selectedLang = storedLang;
      this.$i18n.locale = storedLang;
    }
  },
};
</script>
