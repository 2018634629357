<style scoped>
.active-mode {
  background-color: rgb(34, 46, 60);
  color: white;
  border-radius: 5px;
}
</style>
<template>
  <div>
   
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $route.params.name }}</h1>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ul class="nav">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click="mode = 1"
                    :class="mode == 1 ? 'active-mode' : ''"
                    >{{ $t("warehouse.availableItems") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click="mode = 2"
                    :class="mode == 2 ? 'active-mode' : ''"
                    >{{ $t("warehouse.orders") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click="mode = 3"
                    :class="mode == 3 ? 'active-mode' : ''"
                    >{{ $t("warehouse.sales") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card" v-if="mode == 1">
            <div class="card-body">
              <div class="col col-lg-3 col-12 form-group my-2">
                <label for="">{{ $t("purchase.productType") }}</label>
                <select
                  @change="loadItems()"
                  class="form-control mt-1"
                  v-model="selectedProductType"
                >
                  <option value="Glass">{{ $t("Glass") }}</option>
                  <option value="Raw Material ">{{ $t("RawMaterial") }}</option>
                </select>
              </div>
              <div class="table-responsive rounded" v-if="warehouseItems">
                <table class="table table-hover my-0">
                  <thead style="background-color: #222e3c">
                    <tr class="text-light text-center">
                      <th>#</th>
                      <th>{{ $t("warehuse.itemName") }}</th>
                      <th v-if="checkGlass()">{{ $t("warehuse.quality") }}</th>
                      <th v-if="checkGlass()">{{ $t("warehuse.thickness") }}</th>
                      <th v-if="checkGlass()">{{ $t("warehuse.dimension") }}</th>
                      <th>{{ checkGlass() ? $t("warehuse.quantityAvaialble") : $t("warehuse.quantityTonesAvaiable") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="text-center"
                      v-for="(item, index) in warehouseItems"
                      :key="'item-' + index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.itemName }}</td>
                      <td v-if="checkGlass()">{{ item.quality }}</td>
                      <td v-if="checkGlass()">{{ item.thickness }} mm</td>
                      <td v-if="checkGlass()">{{ item.dimensionW }} X {{ item.dimensionH }}</td>
                      <td>{{ item.quantityRemaining }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card" v-if="mode == 2">
            <div class="card-body">
              <OrderedItemsTab />
            </div>
          </div>
          <div class="card" v-if="mode == 3">
            <div class="card-body">
              <SoldItemsTab />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "@/api";
import OrderedItemsTab from "@/components/Warehouse/OrderedItemsTab";
import SoldItemsTab from "@/components/Warehouse/SoldItemsTab.vue";
export default {
  name: "WarehouseSingle",
  components: {
    OrderedItemsTab,
    SoldItemsTab,
  },
  data() {
    return {
      selectedProductType: "Glass",
      warehouseItems: null,
      sales: null,
      mode: 1,
    };
  },
  methods: {
    checkGlass() {
      return this.selectedProductType == "Glass";
    },
    async loadItems() {
      this.$loading(true);
      const URL = `/api/WarehouseReports/GetSpecificWarehouseItems?warehouseId=${this.$route.params.id}&productType=${this.selectedProductType}`;
      this.warehouseItems = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadItems();
  },
};
</script>
