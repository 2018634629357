<template>
    <div>
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Create Order</h1>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <form action="" @submit.prevent="addNewOrder">
                                <div class="row">
                                    <div class="col col-lg-4 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t('Order.orderBookingNumber') }}</label>
                                            <input required type="text" class="form-control mt-1"
                                                v-model="orderBody.bookingNo"
                                                :placeholder="$t('Order.orderBookingNumber').toString()">
                                        </div>
                                    </div>
                                    <div class="col col-lg-4 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t('Order.containers') }}</label>
                                            <input required type="text" class="form-control mt-1"
                                                v-model="orderBody.containers"
                                                :placeholder="$t('Order.containers').toString()">
                                        </div>
                                    </div>

                                    <div class="col col-lg-4 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t('Order.tones') }}</label>
                                            <input required type="number" step="any" class="form-control mt-1"
                                                v-model.number="orderBody.tones"
                                                :placeholder="$t('Order.tones').toString()">
                                        </div>
                                    </div>
                                    <br><br><br><br>
                                    <div class="col col-lg-3 col-8 mt-1">
                                        <div class="form-group">
                                            <label for="">Unit Price</label>
                                            <input required type="number" step="any" class="form-control mt-1" placeholder="Unit Price"
                                                v-model.number="orderBody.buyPrice">
                                        </div>
                                    </div>
                                    <div class="col col-lg-1 col-4 mt-1">
                                        <div class="form-group">
                                            <label for="">Currency</label>
                                            <select required class="form-control mt-1" v-model.number="orderBody.buyPriceCurrency">
                                                <option name="" id="" value="1">$</option>
                                                <option name="" id="" value="2">¥</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div class="col col-lg-4 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t("Order.totalUnitPrice") }}</label>
                                            <input disabled type="number" step="any" class="form-control mt-1"
                                                v-model.number="orderBody.totalUnitPrice"
                                                :placeholder="$t('Order.totalUnitPrice').toString()">
                                        </div>
                                    </div>
                                    
                                    
                                    <br><br><br><br>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-lg-1 col-12 mt-1">
                                        <button class="w-100 btn btn-success" type="submit">{{ $t("Order.add") }}</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { post } from '@/api'
export default {
    name: 'OrderCreate',
    data() {
        return {
            orderBody: {
                bookingNo: null,
                containers: null,
                tones: 0.0,
                buyPrice: 0.0,
                buyPriceCurrency: 1,
                get totalUnitPrice() {
                    return `${this.tones * this.buyPrice}`;
                },
                expense: 0.0,
                expenseCurrency: 1,

            }
        }
    },

    methods: {
        async addNewOrder() {
            this.$loading(true)
            const URL = "/API/Orders"
            try {
                await post(URL, this.orderBody)
                this.$toast.open({
                    message: "New Order added",
                    type: 'success',
                });
                this.$router.push("/Orders/List")
            } catch (error) {
                console.log(error);
                this.$toast.open({
                    message: "An Error Occured",
                    type: 'error',
                });
            } finally {
                this.$loading(false)
            }
        }
    }
}
</script>