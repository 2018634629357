<template>
  <div>
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-success" @click="openModal">
      {{ $t("addNew") }}
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="saveChanges()">
            <div class="modal-body">
              <div v-for="(field, index) in fields" :key="index">
                <div class="form-group mt-2">
                  <label v-if="!field.type.includes('hidden')">{{ field.title }}</label>
                  <input
                    class="form-control mt-2"
                    v-if="field.type === 'string'"
                    type="text"
                    :name="field.name"
                    v-model="formData[field.name]"
                  />
                  <input
                    class="form-control mt-2"
                    v-else-if="field.type === 'date'"
                    type="date"
                    :name="field.name"
                    v-model="formData[field.name]"
                  />
                  <input
                    class="form-control mt-2"
                    v-else-if="field.type === 'number'"
                    type="number"
                    v-model="formData[field.name]"
                  />
                  <select
                    v-else-if="field.type === 'select'"
                    class="form-control mt-2"
                    v-model="formData[field.name]"
                  >
                    <option
                      :value="item.value"
                      v-for="(item, index) in field.selectValues"
                      :key="'selecetValue-' + index"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer" dir="ltr">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                id="close-btn"
              >
                {{ $t("modal.close") }}
              </button>
              <button type="submit" class="btn btn-primary">
                {{ $t("modal.save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/api";
import $ from "jquery";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  watch: {
    isUpdating(newVal) {
      if (newVal) {
        this.formData = { ...this.initialValues };
      } else {
        this.formData = {};
      }
    },
    initialValues(newVal) {
      if (newVal) {
        this.formData = { ...newVal };
      }
    },
  },
  methods: {
    openModal() {
      this.$emit("data-updated");
      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"), {
        keyboard: false,
      });
      myModal.show();
    },
    closeModal() {
      this.show = false;
      this.$emit("close");
    },
    async saveChanges() {
      this.$loading(true);
      const URL = this.url;
      await post(URL, this.formData, true);
      this.formData = {};
      $("#close-btn").click();
      this.$emit("data-updated");
      this.$loading(false);
    },
  },
};
</script>
