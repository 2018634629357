<template>
  <nav class="mt-4">
      <ul class="pagination justify-content-center">
          <li :class="['page-item', { disabled: currentPage === 1 }]">
              <a class="page-link" @click.prevent="setPage(1)" href="#" style="border-radius: 10px;" ><i class="fa fa-angle-double-left"></i></a>
          </li>
          <li :class="['page-item', { disabled: currentPage === 1 }]">
              <a class="page-link" @click.prevent="setPage(currentPage - 1)" href="#"><i class="fa fa-angle-left"></i></a>
          </li>
          <li v-if="showEllipsisStart" class="page-item disabled">
              <a class="page-link" href="#">...</a>
          </li>
          <li v-for="page in visiblePages" :key="page" :class="['page-item', { active: page === currentPage }]">
              <a class="page-link" @click.prevent="setPage(page)" href="#">{{ page }}</a>
          </li>
          <li v-if="showEllipsisEnd" class="page-item disabled">
              <a class="page-link" href="#">...</a>
          </li>
          <li :class="['page-item', { disabled: currentPage === totalPages }]">
              <a class="page-link" @click.prevent="setPage(currentPage + 1)" href="#"><i
                      class="fa fa-angle-right"></i></a>
          </li>
          <li :class="['page-item', { disabled: currentPage === totalPages }]">
              <a class="page-link" @click.prevent="setPage(totalPages)" href="#" style="border-radius: 10px;"><i
                      class="fa fa-angle-double-right"></i></a>
          </li>
      </ul>
  </nav>
</template>

<script>

export default {
  props: {
      pageIndex: {
          type: Number,
          required: true,
      },
      totalPages: {
          type: Number,
          required: true,
      },
      onPageChange: {
          type: Function,
          required: true,
      },
  },
  computed: {
      currentPage() {
          return this.pageIndex;
      },
      visiblePages() {
          const pages = [];
          const currentPage = this.currentPage;
          const totalPages = this.totalPages;

          // Compute start and end of the visible range of pages
          let startPage = currentPage - 1;
          let endPage = currentPage + 1;

          // Ensure startPage is at least 1
          if (startPage < 1) {
              startPage = 1;
          }

          // Ensure endPage is not greater than totalPages
          if (endPage > totalPages) {
              endPage = totalPages;
          }

          // Add visible pages to array
          for (let i = startPage; i <= endPage; i++) {
              pages.push(i);
          }

          return pages;
      },
      showEllipsisStart() {
          return this.visiblePages[0] > 2;
      },
      showEllipsisEnd() {
          return this.visiblePages[this.visiblePages.length - 1] < this.totalPages - 1;
      },
  },
  methods: {
      setPage(page) {
          if (page !== this.currentPage) {
              this.onPageChange(page);
          }
      },
  },
};
</script>

<style>
.pagination-container {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: none;
  background-color: #e9e9e9;
  color: #777;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.page-link:hover {
  background-color: #ccc;
  text-decoration: none;
  color: black;
}

.page-item.active .page-link {
  background-color: rgb(34, 46, 60);
  color: #fff;
}

.page-item.disabled .page-link {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 576px) {
  .page-link {
      width: 30px;
      height: 30px;
      font-size: 14px;
      border-radius: 10px;
  }
}
</style>