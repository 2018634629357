<style scoped>
.custom-bordered-table {
  border: 2px solid #ff5733; /* Change this to your desired border color */
}
</style>

<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <a role="button" class="btn btn-success mb-2" v-print="'#printMe'">Print</a>
          <div class="card" id="printMe">
            <div class="card-body">
              <div class="page-header text-center">
                <h6 class="font-bold">SHENYU GLASS TRADE CO. , LTD</h6>
                <span class="text-secondary"
                  >ADD: GANGXING STR, KAIFA DIST, DALIAN, LIAONING PROVINCE CHINA</span
                >
              </div>
              <hr class="bg-dark" style="height: 5px" />
              <div class="page-header-secondary text-center">
                <h6 class="font-bold">PROFORMA INVOICE</h6>
                <div class="d-flex justify-content-around" v-if="details">
                  <h6>DATE: {{ cleanDate(details.creationDate) }}</h6>
                  <h6>REF NO: {{ details.saleNo }}</h6>
                </div>
              </div>
              <div class="my-company-info my-2">
                <h6 class="font-bold">SELLER: SHENYU GLASS TRADE CO.,LTD</h6>
                <h6 class="font-bold">
                  ADDRESS: GANGXING STR, KAIFA DIST, DALIAN,LIAONING PROVINCE, CHINA
                </h6>
                <h6 class="font-bold">PHONE: +86411 87510878</h6>
              </div>
              <div
                class="buyer-company-info my-4"
                v-if="details && details.buyerCompanyModel"
              >
                <h6 class="font-bold text-uppercase">BUYER: {{ details.buyerCompanyModel.name }}</h6>
                <h6 class="font-bold text-uppercase">
                  ADDRESS: {{ details.buyerCompanyModel.contact }}
                </h6>
                <h6 class="font-bold text-uppercase">
                  EMAIL: {{ details.buyerCompanyModel.emailAddress }}
                </h6>
              </div>
              <div class="head-desc my-4">
                <span class="d-block"
                  >THE UNDERSIGNED SELLER AND BUYER HAVE AGREED TO CLOSE THE FOLLOWING
                  TRANSACTIONS</span
                >
                <span class="d-block"
                  >ACCORDING TO THE TERMS AND CONDITIONS STIPULATED BELOW</span
                >
              </div>

              <div class="desc-1-table" v-if="details">
                <h6>1. DESCRIPTION OF GOODS</h6>
                <table class="table table-bordered">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">DESCRIPTION OF GOODS</th>
                      <th scope="col" v-if="checkGlass()">SIZE(MM)</th>
                      <th scope="col" v-if="checkGlass()">PCS/CRAT</th>
                      <th scope="col" v-if="checkGlass()">CRTAES</th>
                      <th scope="col" :colspan="checkGlass() ? '' : '4'">
                        {{ checkGlass() ? "SQM" : "TONS" }}
                      </th>
                      <th scope="col">UNIT PRICE {{ details.deliveryStatus + " " + details.portName }}</th>
                      <th scope="col">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody v-if="details.saleSubs">
                    <tr
                      v-for="(item, index) in details.saleSubs"
                      :key="'sale-subs' + index"
                    >
                      <td>
                        <span v-if="checkGlass()">{{ item.thinkness + "MM " }}</span>
                        {{ item.type.name }}
                      </td>
                      <td class="text-center" v-if="checkGlass()">
                        {{ item.dimensionW + "*" + item.dimensionH }}
                      </td>
                      <td class="text-center" v-if="checkGlass()">
                        {{ item.sheetsPerCrate }}
                      </td>
                      <td class="text-center" v-if="checkGlass()">
                        {{ item.numberOfCrate }}
                      </td>
                      <td class="text-center" :colspan="checkGlass() ? '' : '4'">
                        {{
                          checkGlass() ? totalSQMPerContainer(item).toFixed(2) : item.numberOfTones
                        }}
                      </td>
                      <td class="text-center">
                        {{ checkGlass() ? item.pricePerSQM.toFixed(2) : item.pricePerTone }}
                      </td>
                      <td class="text-center">
                        {{ totalAmount(item).toFixed(2).toLocaleString() }}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="12">
                        Seaworthy plywood crates and powder/paper interlayer
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">TOTAL VALUES</td>
                      <td :colspan="checkGlass() ? '3' : '5'">
                        {{ checkGlass() ? finalTotalCrates : finalTotalTones }}
                        {{ checkGlass() ? "Crates" : "Tones" }}
                      </td>
                      <td v-if="checkGlass()" colspan="1" class="text-center">
                        {{ finalTotalSQM.toFixed(2) }}
                      </td>
                      <td v-if="checkGlass()"></td>
                      <td class="text-center">{{ finalTotal.toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div><h6>2. SHIPPING MARK: N/M</h6></div>
              <div><h6>3. SHIPMENT: FROM CHINA PORT</h6></div>
              <div>
                <h6>
                  4. TERM OF PAYMENT: 50% ADVANCE PAYMENT BALANCE PAYMENT BEFORE DELIVERY
                </h6>
              </div>
              <div>
                <h6 class="text-justify">
                  5. CLAIMS: AS PER CHINESE STANDARD: GB11614-2009 AUTO GRADE, IF ANY
                  QUALITY PROBLEM, THE BUYER SHALL SEND THE WRITTEN CLAIM REPORT TO THE
                  SELLER, WITHIN 15 DAYS AFTER RECEIVING THE GOODS. THE SELLER SHALL
                  REPLCE THE UNQUALIFIED GLASS OR MAKE COMPENSATION FOR DIRECT COST OF THE
                  LOSS, AS PER INDEPENTDENT AGENT INSPECTION REPORT. THE CLAIM OF BROKEN,
                  FOR FOB AND EX WORKS,THE SELLER DO NOT COMPENSATE ACCORDING TO THE
                  PICTURES OF PACKING.3% BROKEN IS ALLOWED.
                </h6>
              </div>
              <div>
                <h6>
                  6. PACKAGE: PACKAGE WITH FIRM FUMIGATION PLANKS AND NEUTRO-PACKING.
                  BETWEEN EVERY TWO PIECES OF GLASS SHALL BE PUT POWDER/PAPER INTERLAYER
                </h6>
              </div>
              <div class="my-3">
                <h6>
                  QUALITY: THE PACKAGE SHALL BE SUITABLE FOR THE DEMAND OF EACH CONTAINER.
                </h6>
              </div>
              <div><h6>7. DELIVERY DATE:7 DAYS FROM PI SIGNED</h6></div>

              <div>
                <h6>
                  8. RESPOSIBILITY FOR BREACH OF CONTRACT: THE BREACH PARTY SHALL DAY FOR
                  THE ECONOMIC LOSSES OF THE OTHER PARTY.
                </h6>
              </div>
              <div>
                <h6>
                  9. RESOLUTION OF CONTRACT DISPUTES: THE BOTH SIDES SHALL CONFEK
                  POSITIVELY, OTHERWISE, RESOLVE THE DISPUTE ACCORDING TO THE RELEVANT
                  CONTPT
                </h6>
              </div>
              <div><h6>10. SELLER'S BANK INFORMATION:</h6></div>
              <div>
                <h6>Beneficiary: SHENYU GLASS TRADE CO.,LTD</h6>
                <h6>Beneficiary Account No: 000228386</h6>
                <h6>Bank Name: DBS BANK (HONG KONG) LTD</h6>
                <h6>Swift Code: DHBKHKHH</h6>
                <h6>
                  Bank Address: 11/F, The Center, 99 Queen's Road Central, Hong Kong
                </h6>
              </div>
              <div>
                <h6>11. PRICE VALIDITY: THE PRICE VALIDITY TO JUL 31, 2023.</h6>
                <h6>12. THE CONTRACT IS DOUBLED FOR THE BUYER AND SELLER RESPECTIVELY.</h6>
              </div>
              <div class="d-flex justify-content-between my-4" v-if="details && details.buyerCompanyModel">
                <h6 class="font-bold">SELLER: SHENYU GLASS TRADE CO.,LTD</h6>
                <h6 class="font-bold text-uppercase">BUYER: {{ details.buyerCompanyModel.name }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "@/api";
export default {
  name: "InvoicePrint",
  data() {
    return {
      details: {
        id: 0,
        saleNo: null,
        purchaseNo: null,
        currencyType: 1,
        initialPaid: 0,
        buyerCompanyId: null,
        buyerCompanyModel: null,
        warehouseId: null,
        note: null,
        productType: "Glass",
        deliveryStatus: null,
        portName: null,
        saleSubs: [],
        creationDate: new Date(),
      },
    };
  },
  computed: {
    totalSQMPerContainer() {
      return (passedData) => {
        const {
          dimensionW,
          dimensionH,
          numberOfCrate,
          sheetsPerCrate,
          numberOfContainer,
        } = passedData;
        return (
          (dimensionW / 1000) *
          (dimensionH / 1000) *
          numberOfCrate *
          sheetsPerCrate *
          numberOfContainer
        );
      };
    },
    totalAmount() {
      return (passedData) => {
        if (this.details.productType == "Glass") {
          const totalSQMPerContainer = this.totalSQMPerContainer(passedData);
          const { pricePerSQM } = passedData;
          return totalSQMPerContainer * pricePerSQM;
        } else {
          const { pricePerTone, numberOfTones, numberOfContainer } = passedData;
          return pricePerTone * numberOfTones * numberOfContainer;
        }
      };
    },
    finalTotal() {
      return this.details.saleSubs.reduce((sum, detail) => {
        const totalAmount = this.totalAmount(detail);
        return sum + totalAmount;
      }, 0);
    },

    finalTotalSQM() {
      return this.details.saleSubs.reduce((sum, detail) => {
        const totalAmount = this.totalSQMPerContainer(detail);
        return sum + totalAmount;
      }, 0);
    },
    finalTotalCrates() {
      return this.details.saleSubs.reduce((sum, detail) => {
        const totalAmount = detail.numberOfCrate;
        return sum + totalAmount;
      }, 0);
    },
    finalTotalTones() {
      return this.details.saleSubs.reduce((sum, detail) => {
        const totalAmount = detail.numberOfTones;
        return sum + totalAmount;
      }, 0);
    },
  },
  methods: {
    checkGlass() {
      return this.details.productType == "Glass";
    },
    async loadSingleSale() {
      this.$loading(true);
      const URL = `/api/sales/GetSingle?saleId=${this.$route.params.id}`;
      const res = await get(URL);
      if (res.saleSubs) {
        res.saleSubs.forEach((element) => {
          element.type = {
            id: element.itemId,
            name: element.type,
            productType: element.productType,
          };
        });
      }
      this.details = {
        id: res.id,
        currencyType: res.currencyType,
        initialPaid: res.initialPaid,
        saleNo: res.saleNo,
        purchaseNo: res.purchaseNo,
        buyerCompanyId: { id: res.buyerCompanyId, name: res.buyerCompanyName },
        warehouseId: { id: res.warehouseId, name: res.warehouseName },
        buyerCompanyModel: res.buyerCompanyModel,
        note: res.note,
        productType: res.productType,
        deliveryStatus: res.deliveryStatus,
        portName: res.portName,
        saleSubs: res.saleSubs,
        creationDate: res.creationDate,
      };
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadSingleSale();
  },
};
</script>
