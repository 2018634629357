<template>
  <div>
    <div class="container-fluid p-0">
      <CreateAttachmentModal
        v-if="$route.params.id"
        type="4"
        :entity-id="$route.params.id"
        :onAttachmentCreation="handleAfterAddAttachment"
      />
      <h1 class="h3 mb-3">{{ $t("Sale.Create") }}</h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveDetails">
                <div class="row">
                  <div class="col col-lg-3 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.SaleNo") }}</label>
                      <input
                        :placeholder="$t('Sale.SaleNo')"
                        type="text"
                        class="form-control mt-2 wide-input"
                        v-model="details.saleNo"
                        required
                      />
                    </div>
                  </div>
                  <div class="col col-lg-4 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.company") }}</label>
                      <vue-multiselect
                        v-model="details.buyerCompanyId"
                        :options="companies"
                        placeholder="Select Company"
                        label="name"
                        track-by="id"
                        class="mt-2"
                      ></vue-multiselect>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.warehouse") }}</label>
                      <vue-multiselect
                        v-model="details.warehouseId"
                        :options="warehouses"
                        placeholder="Select warehouse"
                        label="name"
                        track-by="id"
                        class="mt-2"
                      ></vue-multiselect>
                    </div>
                  </div>
                  <div class="col col-lg-2 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.saleAmount") }}</label>
                      <input
                        type="number"
                        step="any"
                        class="form-control mt-2 wide-input"
                        :value="finalTotal"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col col-lg-1 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.currency") }}</label>
                      <select
                        required
                        class="form-control mt-2 wide-input"
                        v-model.number="details.currencyType"
                      >
                        <option value="1">$</option>
                        <option value="2">¥</option>
                      </select>

                      <!-- <div class="row">
                        <div class="col-9">
                          <input
                            v-model="details.initialPaid"
                            type="number"
                            step="any"
                            class="form-control mt-2 wide-input"
                            :placeholder="$t('Sale.amountPaid')"
                            required
                          />
                        </div>
                        <div class="col-3">
                          <select
                            required
                            class="form-control mt-2 wide-input"
                            v-model="details.currencyType"
                          >
                            <option value="1">$</option>
                            <option value="2">¥</option>
                          </select>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="col col-lg-2 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.remaining") }}</label>
                      <input
                        type="number"
                        step="any"
                        class="form-control mt-2 wide-input"
                        :value="amountRemaining"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col col-lg-2 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.productType") }}</label>
                      <select
                        :disabled="details.saleSubs.length > 0"
                        @change="loadItems()"
                        class="form-control mt-2 wide-input"
                        v-model="details.productType"
                      >
                        <option value="Glass">{{ $t("Glass") }}</option>
                        <option value="Raw Material ">{{ $t("RawMaterial") }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col col-lg-2 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.FromPurchaseNo") }}</label>
                      <input
                        type="text"
                        v-model="details.purchaseNo"
                        class="form-control mt-2 wide-input"
                      />
                    </div>
                  </div>
                  <div class="col col-lg-2 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("purchase.deliveryStatus") }}</label>
                      <select
                        class="form-control mt-2 wide-input"
                        v-model="details.deliveryStatus"
                      >
                        <option value="FOB">FOB</option>
                        <option value="EXW ">EXW</option>
                        <option value="CIF ">CIF</option>
                      </select>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("purchase.portName") }}</label>
                      <input
                        type="text"
                        class="form-control mt-2 wide-input"
                        v-model="details.portName"
                      />
                    </div>
                  </div>
                  <div class="col col-lg-3 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.bookingNo") }}</label>
                      <input
                        type="text"
                        v-model="details.bookingNo"
                        class="form-control mt-2 wide-input"
                      />
                    </div>
                  </div>
                  <div class="col col-lg-6 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.containerNo") }}</label>
                      <textarea
                        type="text"
                        v-model="details.containerNo"
                        class="form-control mt-2"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-12 mt-2">
                    <div class="form-group">
                      <label for="">{{ $t("Sale.note") }}</label>
                      <input
                        type="text"
                        v-model="details.note"
                        class="form-control mt-2 wide-input"
                      />
                    </div>
                  </div>

                  <div
                    class="col col-lg-1 col-12 mt-2"
                    v-if="details.saleSubs.length > 0"
                  >
                    <div class="form-group">
                      <label for="" class="d-block" style="visibility: hidden"
                        >sffffs</label
                      >
                      <button class="btn btn-success mt-2">{{ $t("save") }}</button>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end my-2">
                  <button
                    type="button"
                    class="btn btn-success btn-sm rounded mx-1"
                    @click="addToDetail()"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
                <div
                  class="table-responsive"
                  v-if="details && details.saleSubs.length > 0"
                >
                  <div class="table-container" style="overflow-x: auto">
                    <table class="table">
                      <thead>
                        <tr class="table-head-color text-light">
                          <th scope="col">#</th>
                          <th scope="col">{{ $t("purchase.type") }}</th>
                          <th v-if="checkGlass()" scope="col">
                            {{ $t("purchase.quality") }}
                          </th>
                          <th v-if="checkGlass()" scope="col">
                            {{ $t("purchase.thickness") }}
                          </th>
                          <th v-if="checkGlass()" scope="col">
                            {{ $t("purchase.dimensionsW") }}
                          </th>
                          <th v-if="checkGlass()" scope="col">
                            {{ $t("purchase.dimensionsH") }}
                          </th>
                          <th scope="col" v-if="checkGlass()">
                            {{ $t("purchase.noOfCrates") }}
                          </th>
                          <th scope="col" v-if="checkGlass()">
                            {{ $t("purchase.sheets") }}
                          </th>
                          <th scope="col">{{ $t("purchase.Containers") }}</th>
                          <th scope="col">
                            {{
                              checkGlass()
                                ? $t("purchase.totalSQM")
                                : $t("purchase.tones")
                            }}
                          </th>
                          <th scope="col">
                            {{
                              checkGlass()
                                ? $t("purchase.pricePerSQM")
                                : $t("purchase.pricePerTone")
                            }}
                          </th>
                          <th scope="col">{{ $t("purchase.totalAmount") }}</th>
                          <td scope="col"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in details.saleSubs" :key="index">
                          <th scope="row">{{ index + 1 }}</th>
                          <td>
                            <vue-multiselect
                              style="width: 400px"
                              v-model="item.type"
                              :options="items"
                              placeholder="Select one"
                              label="name"
                              track-by="name"
                            ></vue-multiselect>
                          </td>
                          <td v-if="checkGlass()">
                            <select
                              class="form-control"
                              v-model="item.quality"
                              style="width: 50px"
                            >
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                            </select>
                          </td>
                          <td v-if="checkGlass()">
                            <input
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              v-model="item.thinkness"
                            />
                            <!-- <select class="form-control" v-model="item.thinkness">
                              <option value="1">1 mm</option>
                              <option value="2">2 mm</option>
                              <option value="3">3 mm</option>
                              <option value="4">4 mm</option>
                              <option value="5">5 mm</option>
                              <option value="6">6 mm</option>
                              <option value="7">7 mm</option>
                              <option value="8">8 mm</option>
                              <option value="9">9 mm</option>
                              <option value="10">10 mm</option>
                              <option value="11">11 mm</option>
                              <option value="12">12 mm</option>
                            </select> -->
                          </td>
                          <td v-if="checkGlass()">
                            <input
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.dimensionsW')"
                              v-model.number="item.dimensionW"
                            />
                          </td>
                          <td v-if="checkGlass()">
                            <input
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.dimensionsH')"
                              v-model.number="item.dimensionH"
                            />
                          </td>
                          <td v-if="checkGlass()">
                            <input
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.noOfCrates')"
                              v-model.number="item.numberOfCrate"
                            />
                          </td>
                          <td v-if="checkGlass()">
                            <input
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.sheets')"
                              v-model.number="item.sheetsPerCrate"
                            />
                          </td>
                          <td>
                            <input
                              style="width: 75px"
                              type="number"
                              class="form-control"
                              :placeholder="$t('purchase.Containers')"
                              v-model.number="item.numberOfContainer"
                            />
                          </td>
                          <td>
                            <input
                              v-if="checkGlass()"
                              style="width: 150px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.totalSQM')"
                              :value="totalSQMPerContainer(item).toFixed(2)"
                              disabled
                            />
                            <input
                              v-else
                              style="width: 150px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.tones')"
                              v-model="item.numberOfTones"
                            />
                          </td>
                          <td>
                            <input
                              v-if="checkGlass()"
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.pricePerSQM')"
                              v-model.number="item.pricePerSQM"
                            />
                            <input
                              v-else
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.pricePerTone')"
                              v-model.number="item.pricePerTone"
                            />
                          </td>
                          <td>
                            <input
                              style="width: 150px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.totalAmount')"
                              :value="totalAmount(item).toFixed(2)"
                              disabled
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm rounded"
                              @click="removeItem(index)"
                              v-if="item.id == 0"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm rounded"
                              @click="deleteDetail(item.id)"
                              v-else
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="$route.params.id">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end my-2">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#CreateAttachmentModal"
                  class="btn btn-warning btn-sm rounded text-dark mx-1"
                >
                  {{ $t("newAttachment") }}
                </button>
              </div>

              <AttachmentList
                v-if="attachments"
                :attachments="attachments"
                :onAttachmentDelete="handleAfterAddAttachment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post, del } from "@/api";
import CreateAttachmentModal from "@/components/CreateAttachmentModal.vue";
import AttachmentList from "@/components/AttachmentList.vue";

export default {
  name: "CreateSale",
  components: {
    CreateAttachmentModal,
    AttachmentList,
  },
  data() {
    return {
      companies: [],
      items: [],
      warehouses: [],
      detailBody: {
        id: 0,
        type: null,
        itemId: 0,
        quality: null,
        thinkness: null,
        dimensionW: 0.0,
        dimensionH: 0.0,
        sheetsPerCrate: 0,
        numberOfCrate: 0,
        numberOfContainer: 1,
        currencyType: 1,
        pricePerSQM: 0.0,
        totalSQMPerContainer: 0.0,
        totalAmount: 0.0,
        productType: null,
        numberOfTones: 0.0,
        pricePerTone: 0.0,
        bookingNo: null,
        containerNo: null,
      },
      details: {
        id: 0,
        saleNo: null,
        purchaseNo: null,
        currencyType: 1,
        initialPaid: 0,
        buyerCompanyId: null,
        warehouseId: null,
        note: null,
        productType: "Glass",
        deliveryStatus: null,
        portName: null,
        saleSubs: [],
      },
      attachments: null,
    };
  },
  computed: {
    totalSQMPerContainer() {
      return (passedData) => {
        const {
          dimensionW,
          dimensionH,
          numberOfCrate,
          sheetsPerCrate,
          numberOfContainer,
        } = passedData;
        return (
          (dimensionW / 1000) *
          (dimensionH / 1000) *
          numberOfCrate *
          sheetsPerCrate *
          numberOfContainer
        );
      };
    },
    totalAmount() {
      return (passedData) => {
        if (this.details.productType == "Glass") {
          const totalSQMPerContainer = this.totalSQMPerContainer(passedData);
          const { pricePerSQM } = passedData;
          return totalSQMPerContainer * pricePerSQM;
        } else {
          const { pricePerTone, numberOfTones, numberOfContainer } = passedData;
          return pricePerTone * numberOfTones * numberOfContainer;
        }
      };
    },
    finalTotal() {
      return this.details.saleSubs.reduce((sum, detail) => {
        const totalAmount = this.totalAmount(detail);
        return sum + totalAmount;
      }, 0);
    },
    amountRemaining() {
      return this.finalTotal - this.details.initialPaid;
    },
  },
  methods: {
    checkGlass() {
      return this.details.productType == "Glass";
    },
    async handleAfterAddAttachment() {
      await this.loadAttachments();
    },
    removeItem(index) {
      this.details.saleSubs.splice(index, 1);
    },
    async deleteDetail(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/sales/delete?saleId=${id}&isSub=true`;
          await del(URL);
          await this.loadSingleSale();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    async loadCompanies() {
      this.$loading(true);
      const URL = `/api/buyerCompanies?page=1&size=1000000`;
      const res = await get(URL);
      this.companies = res.items;
      this.$loading(false);
    },
    async loadWarehouses() {
      this.$loading(true);
      const URL = `/api/warehouses?page=1&size=100`;
      const res = await get(URL);
      this.warehouses = res.items;
      this.$loading(false);
    },
    async loadItems() {
      this.$loading(true);
      const URL = `/api/items?page=1&size=10000000&isForMuliSelect=true&productType=${this.details.productType}`;
      const res = await get(URL);
      this.items = res.items;
      this.$loading(false);
    },
    async loadSingleSale() {
      this.$loading(true);
      const URL = `/api/sales/GetSingle?saleId=${this.$route.params.id}`;
      const res = await get(URL);
      if (res.saleSubs) {
        res.saleSubs.forEach((element) => {
          element.type = {
            id: element.itemId,
            name: element.type,
            productType: element.productType,
          };
        });
      }
      this.details = {
        id: res.id,
        currencyType: res.currencyType,
        initialPaid: res.initialPaid,
        saleNo: res.saleNo,
        purchaseNo: res.purchaseNo,
        buyerCompanyId: { id: res.buyerCompanyId, name: res.buyerCompanyName },
        warehouseId: { id: res.warehouseId, name: res.warehouseName },
        note: res.note,
        productType: res.productType,
        deliveryStatus: res.deliveryStatus,
        portName: res.portName,
        saleSubs: res.saleSubs,
        bookingNo: res.bookingNo,
        containerNo: res.containerNo,
      };
      this.$loading(false);
    },
    resetProperties() {
      this.detailBody = {
        id: 0,
        type: null,
        itemId: 0,
        quality: null,
        thinkness: null,
        dimensionW: 0.0,
        dimensionH: 0.0,
        sheetsPerCrate: 0,
        numberOfCrate: 0,
        numberOfContainer: 1,
        currencyType: 1,
        pricePerSQM: 0.0,
        totalSQMPerContainer: 0.0,
        totalAmount: 0.0,
        productType: null,
        numberOfTones: 0.0,
        pricePerTone: 0.0,
      };
    },
    addToDetail() {
      this.details.saleSubs.push(this.detailBody);
      this.resetProperties();
    },
    async saveDetails() {
      if (!this.details.buyerCompanyId) return;
      this.$loading(true);

      this.details.buyerCompanyId = this.details.buyerCompanyId.id;
      this.details.warehouseId = this.details.warehouseId.id;

      this.details.saleSubs.forEach((detail) => {
        detail.totalSQMPerContainer = this.totalSQMPerContainer(detail);
        detail.totalAmount = this.totalAmount(detail);
        detail.itemId = detail.type.id;
        detail.productType = detail.type.productType;
        detail.type = detail.type.name;
        detail.currencyType = this.details.currencyType;
      });

      const URL = "/api/sales";
      const res = await post(URL, this.details);
      this.$loading(false);
      if (res != "err") {
        this.$router.push("/Sales/List");
      }
    },
    async loadAttachments() {
      this.$loading(true);
      var URL = `/api/attachments?entityId=${this.$route.params.id}&type=4`;
      this.attachments = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadCompanies();
    await this.loadItems();
    await this.loadWarehouses();
    if (this.$route.params.id) {
      await this.loadSingleSale();
      await this.loadAttachments();
    }
  },
};
</script>
