<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("reports.dailyTransactions") }}</h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="filters d-lg-flex justify-content-between">
                <div class="col col-lg-8 col-12 d-lg-flex my-2" style="gap: 10px">
                  <div class="form-group col col-lg-4 col-12">
                    <label>{{ $t("from") }}</label>
                    <input type="date" class="form-control" v-model="from" />
                  </div>
                  <div class="form-group col col-lg-4 col-12">
                    <label>{{ $t("to") }}</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="to"
                      @change="loadTransactions()"
                    />
                  </div>
                </div>
                <div class="col col-lg-4 col-12 my-2">
                  <div class="form-group">
                    <label>{{ $t("filterHhere") }}</label>
                    <select
                      class="form-control"
                      v-model="selectedType"
                      @change="loadTransactions"
                    >
                      <option value="5">{{ $t("filter.all") }}</option>
                      <option value="1">{{ $t("filter.buyerPayment") }}</option>
                      <option value="2">{{ $t("filter.CompanyExpense") }}</option>
                      <option value="3">{{ $t("filter.orderPayment") }}</option>
                      <option value="4">{{ $t("filter.orderExpense") }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                class="table-responsive rounded"
                v-if="transactions && transactions.items && transactions.items.length > 0"
              >
                <table class="table table-hover my-0">
                  <thead style="background-color: #222e3c">
                    <tr class="text-light">
                      <th>#</th>
                      <th>{{ $t("expense.date") }}</th>
                      <th>{{ $t("transaction.type") }}</th>

                      <th>{{ $t("transaction.entity") }}</th>
                      <th>{{ $t("transaction.action") }}</th>
                      <th>{{ $t("expense.amount") }}</th>
                      <th>{{ $t("expense.note") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(transaction, index) in transactions.items"
                      :key="'transaction-' + transaction.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ cleanDate(transaction.creationDate) }}</td>
                      <td>
                        <span :class="getTransactionClass(transaction.transactionType)">
                          {{ transaction.transactionTypeName }}
                        </span>
                      </td>

                      <td>{{ transaction.entityName }}</td>
                      <td>{{ transaction.actionName }}</td>
                      <td>
                        {{ getCurrency(transaction.currencyType) }}
                        {{ transaction.amount.toLocaleString() }}
                      </td>
                      <td>{{ transaction.note }}</td>
                      <td>
                        <button
                          :disabled="transaction.note == 'Initial Paid'"
                          class="btn btn-danger mx-1 my-1 btn-sm rounded"
                          @click="deleteRow(transaction.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
                :pageIndex="pageIndex"
                :totalPages="totalPages"
                :onPageChange="handlePageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, del } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
export default {
  name: "DailyTransaction",
  data() {
    return {
      transactions: null,
      from: null,
      to: null,

      pageIndex: 1,
      pageSize: 10,

      selectedType: 5,
    };
  },
  components: {
    Pagination,
  },
  computed: {
    totalItems() {
      return this.transactions ? this.transactions.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.transactions.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadTransactions();
    },
    async loadTransactions() {
      this.$loading(true);
      let URL = null;
      if (this.from && this.to) {
        URL = `/api/transactions?type=${this.selectedType}&page=${this.pageIndex}&size=${this.pageSize}&from=${this.from}&to=${this.to}`;
      } else {
        URL = `/api/transactions?type=${this.selectedType}&page=${this.pageIndex}&size=${this.pageSize}`;
      }

      this.transactions = await get(URL);
      this.$loading(false);
    },
    async deleteRow(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/transactions/Delete?id=${id}`;
          await del(URL, true);
          await this.loadTransactions();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
  },
  async mounted() {
    await this.loadTransactions();
  },
};
</script>
