<template>
    <div>
      <div class="container-fluid p-0">
        <h1 class="h3 mb-3">{{ $t("buyerCompanies.Title") }}</h1>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <DataTable
                  :table-config="category"
                  :modal-create-title="$t('buyerCompanies.add')"
                  :modal-update-title="$t('buyerCompanies.update')"
                ></DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import DataTable from "@/components/DataTable.vue";
  export default {
    name: "buyerCompaniesView",
    components: {
      DataTable,
    },
    data() {
      return {
        showModal: false,
        category: {
          url: "/api/buyerCompanies",
          fields: [
            {
              title: this.$t("buyerCompanies.TitleSingle"),
              name: "name",
              redirect: {
                url: '/BuyerCompanies/Profile',
                params: ['id']
              },
              type: "string",
              visible: true,
            },
            {
              title: this.$t("buyerCompanies.contactSingle"),
              name: "contact",
              type: "string",
              visible: true,
            },
            {
              title: this.$t("buyerCompanies.emailAddress"),
              name: "emailAddress",
              type: "string",
              visible: true,
            },
            {
              title: this.$t("buyerCompanies.totalDebtDollar"),
              name: "totalOweDollar",
              type: "hidden-number",
              visible: this.checkRootAdmin() || this.checkAdmin(),
            },
            {
              title: this.$t("buyerCompanies.totalDebtYuan"),
              name: "totalOweYuan",
              type: "hidden-number",
              visible: this.checkRootAdmin() || this.checkAdmin(),
            },
          ],
        },
      };
    },
    methods: {},
    mounted() {},
  };
  </script>