<template>
    <div>
      <div class="container-fluid p-0">
        <h1 class="h3 mb-3">{{ $t("companyExpenseTypes.Title") }}</h1>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <DataTable
                  :table-config="category"
                  :modal-create-title="$t('companyExpenseTypes.add')"
                  :modal-update-title="$t('companyExpenseTypes.update')"
                ></DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import DataTable from "@/components/DataTable.vue";
  export default {
    name: "buyerCompaniesView",
    components: {
      DataTable,
    },
    data() {
      return {
        showModal: false,
        category: {
          url: "/api/companyExpenseTypes",
          fields: [
            {
              title: this.$t("companyExpenseTypes.titleEn"),
              name: "titleEn",
              type: "string",
              visible: true,
            },
            {
              title: this.$t("companyExpenseTypes.titlezh"),
              name: "titleZh",
              type: "string",
              visible: true,
            },
            
          ],
        },
      };
    },
    methods: {},
    mounted() {},
  };
  </script>