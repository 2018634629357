<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("expenses.daily") }}</h1>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("expense.createOrUpdate") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form class="form" @submit.prevent="saveTransaction">
              <div class="modal-body">
                <div class="form-group" v-if="expenseTypes">
                  <label for="">{{ $t("expense.expenseType") }}</label>
                  <select class="form-control mt-1" v-model="transactionBody.entityId">
                    <option
                      v-for="type in expenseTypes"
                      :key="'expenseType-' + type.id"
                      :value="type.id"
                    >
                      {{ type.titleEn }} - {{ type.titleZh }}
                    </option>
                  </select>
                </div>
                <div class="form-group mt-1">
                  <label for="">{{ $t("expense.date") }}</label>
                  <input
                    class="form-control mt-1"
                    type="date"
                    v-model="transactionBody.creationDate"
                  />
                </div>
                <div class="form-group mt-1">
                  <label for="">{{ $t("expense.amount") }}</label>
                  <input
                    class="form-control mt-1"
                    type="number"
                    step="any"
                    v-model="transactionBody.amount"
                  />
                </div>
                <div class="form-group mt-1">
                  <label for="">{{ $t("expense.currency") }}</label>
                  <select
                    class="form-control mt-1"
                    v-model.number="transactionBody.currencyType"
                  >
                    <option value="1">$</option>
                    <option value="2">¥</option>
                  </select>
                </div>
                <div class="form-group mt-1">
                  <label for="">{{ $t("expense.detail") }}</label>
                  <input
                    type="text mt-1"
                    class="form-control"
                    v-model="transactionBody.note"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="close-btn"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {{ $t("purchase.close") }}
                </button>
                <button type="submit" class="btn btn-primary">
                  {{ $t("purchase.save") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="head d-lg-flex justify-content-between my-2">
                <button
                  @click="resetProperties()"
                  class="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {{ $t("expenses.addNew") }}
                </button>
                <div class="filters d-lg-flex" style="gap: 10px;">
                  <div class="date-filters">
                    <label for="">{{ $t("filters.from") }}</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="selectedDateFrom"
                    />
                  </div>
                  <div class="date-filters">
                    <label for="">{{ $t("filters.to") }}</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="selectedDateTo"
                      @input="loadExpenses"
                    />
                  </div>
                </div>
              </div>
              <div
                class="table-responsive rounded"
                v-if="expenses && expenses.items && expenses.items.length > 0"
              >
                <table class="table table-hover my-0">
                  <thead style="background-color: #222e3c">
                    <tr class="text-light">
                      <th>#</th>
                      <th>{{ $t("expense.date") }}</th>
                      <th>{{ $t("expense.expenseType") }}</th>
                      <th>{{ $t("expense.amount") }}</th>
                      <th>{{ $t("expense.note") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(expense, index) in expenses.items"
                      :key="'expense-' + expense.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ cleanDate(expense.creationDate) }}</td>
                      <td>{{ expense.entityName }}</td>
                      <td>
                        {{ getCurrency(expense.currencyType) }}
                        {{ expense.amount.toLocaleString() }}
                      </td>
                      <td>{{ expense.note }}</td>
                      <td>
                        <button
                          class="btn btn-danger mx-1 my-1 btn-sm rounded"
                          @click="deleteRow(expense.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                        <button
                          class="btn btn-primary mx-1 my-1 btn-sm rounded"
                          @click="editRow(expense)"
                        >
                          <i class="fa fa-pen"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
                :pageIndex="pageIndex"
                :totalPages="totalPages"
                :onPageChange="handlePageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post, del } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
import $ from "jquery";
export default {
  name: "ExpensesList",
  data() {
    return {
      expenseTypes: null,
      expenses: null,
      selectedDateFrom: null,
      selectedDateTo: null,

      transactionBody: {
        id: 0,
        transactionType: 2,
        amount: 0,
        currencyType: 1,
        entityId: 0,
        note: null,
        creationDate: new Date(),
      },

      pageIndex: 1,
      pageSize: 10,
    };
  },
  components: {
    Pagination,
  },
  computed: {
    totalItems() {
      return this.expenses ? this.expenses.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.expenses.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadExpenses();
    },
    async deleteRow(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/transactions/Delete?id=${id}`;
          await del(URL, true);
          await this.loadExpenses();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    editRow(transaction) {
      this.transactionBody = {
        id: transaction.id,
        transactionType: 2,
        amount: transaction.amount,
        currencyType: transaction.currencyType,
        entityId: transaction.entityId,
        note: transaction.note,
        creationDate: this.formatDateAndAssignToInput(transaction.creationDate),
      };
      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"), {
        keyboard: false,
      });
      myModal.show();
    },
    resetProperties() {
      this.transactionBody = {
        id: 0,
        transactionType: 2,
        amount: 0,
        currencyType: 1,
        entityId: 0,
        note: null,
        creationDate: new Date(),
      };
    },
    async loadExpenseTypes() {
      this.$loading(true);
      const URL = "/api/companyExpenseTypes?page=1&size=10000";
      const res = await get(URL);
      this.expenseTypes = res.items;
      this.$loading(false);
    },
    async loadExpenses() {
      this.$loading(true);
      let URL = null;
      if (this.selectedDateFrom) {
        URL = `/api/transactions?type=2&from=${this.selectedDateFrom}&to=${this.selectedDateTo}&page=${this.pageIndex}&size=${this.pageSize}`;
      }else {
        URL = `/api/transactions?type=2&page=${this.pageIndex}&size=${this.pageSize}`;
      }

      const res = await get(URL);
      this.expenses = res;
      this.$loading(false);
    },

    async saveTransaction() {
      this.$loading(true);
      const URL = "/api/transactions";
      await post(URL, this.transactionBody);
      await this.resetProperties();
      await this.loadExpenses();
      $("#close-btn").click();
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadExpenseTypes();
    await this.loadExpenses();
  },
};
</script>
