<template>
    <div>
        <!-- Modal -->
        <div class="modal fade " id="ViewAttachmentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div class="modal-content" v-if="selectedAttachment">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ selectedAttachment.description }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-0" >
                        <div style="width: 100%; height:75vh" v-if="selectedAttachment.imageLink.split('.')[1] === 'pdf'">
                            <iframe :src="$hostname + selectedAttachment.imageLink" frameborder="0" style="width: 100%; height:100%"></iframe>
                        </div>
                        <img v-else :src="$hostname + selectedAttachment.imageLink" alt="" style="width: 100%; height:auto">
                       
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["selectedAttachment"]
}
</script>