<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("settings.languageSettings") }}</h1>

      <div class="row">
        <div class="col col-lg-4 col-12">
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label for="">{{ $t("langugae") }}</label>
                <select
                  class="form-control mt-2"
                  @change="changeLang"
                  v-model="selectedLang"
                >
                  <option value="en">English</option>
                  <option value="zh">中国人</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LangComponent",
  data() {
    return {
      selectedLang: localStorage.getItem("selectedLang") || "en",
    };
  },
  methods: {
    changeLang() {
      this.$i18n.locale = this.selectedLang;
      localStorage.setItem("selectedLang", this.selectedLang);
    },
  },
  mounted() {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      this.selectedLang = storedLang;
      this.$i18n.locale = storedLang;
    }
  },
};
</script>
