<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("companiesList") }}</h1>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("companies.addNew") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form class="form" @submit.prevent="addNewCompany">
              <div class="modal-body">
                <div class="form-group">
                  <label for="">{{ $t("companies.country") }}</label>
                  <select class="form-control mt-1" v-model="companyBody.countryId">
                    <option
                      v-for="country in countries"
                      :key="'country-' + country.id"
                      :value="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group my-2">
                  <label for="">{{ $t("companies.name") }}</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    v-model="companyBody.name"
                    required
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="close-btn"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {{ $t("purchase.close") }}
                </button>
                <button type="submit" class="btn btn-primary">
                  {{ $t("purchase.save") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="head row justify-content-between align-items-start">
                <div class="col col-lg-2 col-12 my-1 d-flex justify-content-start">
                  <button
                    class="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    {{ $t("companies.addNew") }}
                  </button>
                </div>
                <div class="col col-lg-3 col-12 my-1">
                  <form action="" @submit.prevent="loadCompanies()">
                    <input type="text" class="form-control" placeholder="search here" v-model="searchText" />
                  </form>
                </div>
                
              </div>
              <div
                class="table-responsive rounded"
                v-if="companies && companies.items && companies.items.length > 0"
              >
                <table class="table table-hover my-0 text-center">
                  <thead style="background-color: #222e3c">
                    <tr class="text-light">
                      <th>#</th>
                      <th>{{ $t("companies.country") }}</th>
                      <th>{{ $t("companies.name") }}</th>
                      <th  v-if="checkRootAdmin() || checkAdmin()">{{ $t("companies.totalDebtDollar") }}</th>
                      <th  v-if="checkRootAdmin() || checkAdmin()">{{ $t("companies.totalDebtYuan") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(company, index) in companies.items"
                      :key="'company-' + company.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ company.country.name }}</td>
                      <td>{{ company.name }}</td>
                      <td  v-if="checkRootAdmin() || checkAdmin()">$ {{ company.totalOweDollar.toLocaleString() }}</td>
                      <td  v-if="checkRootAdmin() || checkAdmin()">¥ {{ company.totalOweYuan.toLocaleString() }}</td>
                      <td>
                        <button
                        v-if="checkRootAdmin() || checkAdmin()"
                          class="btn btn-primary btn-sm rounded my-1 mx-1"
                          @click="updateFields(company)"
                        >
                          <i class="fa fa-pen"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <Pagination
                :pageIndex="pageIndex"
                :totalPages="totalPages"
                :onPageChange="handlePageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
import $ from "jquery";
export default {
  name: "CompaniesList",
  components: {
    Pagination,
  },
  data() {
    return {
      searchText: null,
      countries: null,
      companies: null,
      companyBody: {
        id: 0,
        countryId: 2,
        name: null,
      },

      pageIndex: 1,
      pageSize: 10,
    };
  },
  computed: {
    totalItems() {
      return this.companies ? this.companies.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.companies.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    updateFields(company) {
      this.companyBody = {
        id: company.id,
        countryId: company.countryId,
        name: company.name,
      };

      this.openModal("exampleModal");
    },
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadCompanies();
    },
    async loadCountries() {
      this.$loading(true);
      const URL = `/getCountriesList`;
      this.countries = await get(URL);

      this.$loading(false);
    },
    async loadCompanies() {
      this.$loading(true);
      const URL = `/api/companies?page=${this.pageIndex}&size=${this.pageSize}&search=${this.searchText}`;
      this.companies = await get(URL);

      this.$loading(false);
    },
    async addNewCompany() {
      this.$loading(true);
      const URL = "/api/companies";
      await post(URL, this.companyBody);
      $("#close-btn").click();
      this.companyBody = {
        id: 0,
        countryId: 2,
        name: null,
      };
      this.loadCompanies();
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadCountries();
    await this.loadCompanies();
  },
};
</script>
