<style scoped>
.border-buttom-danger {
  border-bottom: 3px solid #dc3545 !important;
}

.border-buttom-primary {
  border-bottom: 3px solid #0d6efd !important;
}

.border-buttom-success {
  border-bottom: 3px solid #39b197 !important;
}
</style>

<template>
  <div class="home">
    <h6>Dashboard</h6>

    <div
      class="modal fade"
      id="exampleModalQasa"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("report.yourWallet") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="the-report">
              <div class="table-responsive">
                <table class="table text-center" v-if="realQasa">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{{ $t("type") }}</th>
                      <th scope="col">{{ $t("amountDollar") }}</th>
                      <th scope="col">{{ $t("amountYuan") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{{ $t("SalePayment") }}</td>
                      <td>
                        <span class="badge bg-success">{{
                          realQasa.salePaymentDollarIn.toLocaleString()
                        }}</span>
                      </td>
                      <td>
                        <span class="badge bg-success">{{
                          realQasa.salePaymentYuanIn.toLocaleString()
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>{{ $t("OrderPayment") }}</td>
                      <td>
                        <span class="badge bg-danger">{{
                          realQasa.orderPaymentDollarOut.toLocaleString()
                        }}</span>
                      </td>
                      <td>
                        <span class="badge bg-danger">{{
                          realQasa.orderPaymentYuanOut.toLocaleString()
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>{{ $t("orderExpensee") }}</td>
                      <td>
                        <span class="badge bg-danger">{{
                          realQasa.orderExpenseDollarOut.toLocaleString()
                        }}</span>
                      </td>
                      <td>
                        <span class="badge bg-danger">{{
                          realQasa.orderExpenseYuanOut.toLocaleString()
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>{{ $t("companyExpense") }}</td>
                      <td>
                        <span class="badge bg-danger">{{
                          realQasa.companyExpenseDollarOut.toLocaleString()
                        }}</span>
                      </td>
                      <td>
                        <span class="badge bg-danger">{{
                          realQasa.companyExpenseYuanOut.toLocaleString()
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div class="qasaQadima" v-if="oldQasa">
              <form action="" @submit.prevent="saveQasaQadima()">
                <div class="row">
                  <div class="col-6 mt-1">
                    <div class="form-group">
                      <label>{{ $t("currentAmountDollar") }}</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="oldQasa.dollarAmount"
                        required
                        :disabled="oldQasa.locked"
                      />
                    </div>
                  </div>
                  <div class="col-6 mt-1">
                    <div class="form-group">
                      <label>{{ $t("currentAmountYuan") }}</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="oldQasa.yuanAmount"
                        required
                        :disabled="oldQasa.locked"
                      />
                    </div>
                  </div>
                  <div class="col-6 mt-1">
                    <div class="form-group">
                      <label>{{ $t("startingDate") }}</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="oldQasa.startingDate"
                        :disabled="oldQasa.locked"
                      />
                    </div>
                  </div>
                  <div class="col-6 mt-1">
                    <div class="form-group">
                      <label>{{ $t("status") }}</label>
                      <select
                        class="form-control"
                        v-model="oldQasa.enabled"
                        :disabled="oldQasa.locked"
                      >
                        <option value="true">{{ $t("enabled") }}</option>
                        <option value="false">{{ $t("disabled") }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 mt-2" v-if="!oldQasa.locked">
                    <button class="btn btn-primary">{{ $t("save") }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row"  v-if="checkRootAdmin() || checkAdmin()">
      <div class="col col-xl-4 col-lg-4 col-12 mt-2" v-if="companiesDebt">
        <div class="card shadow border-bottom-success p-1 border-buttom-primary">
          <div class="card-body">
            <div class="the-content d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column">
                <h6 class="font-bold text-primary">
                  {{ $t("report.companiesDebt") }}
                </h6>
                <h3 class="my-1">
                  {{ $t("currency.dollar") }}
                  {{ companiesDebt.totalDollar.toLocaleString() }}
                </h3>
                <h6 class="my-1">
                  {{ $t("currency.yuan") }}
                  {{ companiesDebt.totalYuan.toLocaleString() }}
                </h6>
              </div>
              <i class="fa fa-money-bill text-secondary icon-bg-size"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-xl-4 col-lg-4 col-12 mt-2" v-if="buyerCompaniesDebt">
        <div class="card shadow border-bottom-success p-1 border-buttom-danger">
          <div class="card-body">
            <div class="the-content d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column">
                <h6 class="font-bold text-danger">
                  {{ $t("report.buyerCompaniesDebt") }}
                </h6>
                <h3 class="my-1">
                  {{ $t("currency.dollar") }}
                  {{ buyerCompaniesDebt.totalDollar.toLocaleString() }}
                </h3>
                <h6 class="my-1">
                  {{ $t("currency.yuan") }}
                  {{ buyerCompaniesDebt.totalYuan.toLocaleString() }}
                </h6>
              </div>
              <i class="fa fa-money-bill text-secondary icon-bg-size"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-xl-4 col-lg-4 col-12 mt-2" v-if="realQasa">
        <a role="button" data-bs-toggle="modal" data-bs-target="#exampleModalQasa">
          <div class="card shadow border-bottom-success p-1 border-buttom-success">
            <div class="card-body">
              <div class="the-content d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                  <h6 class="font-bold text-success">
                    {{ $t("report.yourWallet") }}
                  </h6>
                  <h3 class="my-1">
                    {{ $t("currency.dollar") }}
                    {{ realQasa.qasaResultDollar.toLocaleString() }}
                  </h3>
                  <h6 class="my-1">
                    {{ $t("currency.yuan") }}
                    {{ realQasa.qasaResultYuan.toLocaleString() }}
                  </h6>
                </div>
                <i class="fa fa-money-bill text-secondary icon-bg-size"></i>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "@/api";
export default {
  name: "HomeView",
  data() {
    return {
      buyerCompaniesDebt: null,
      companiesDebt: null,

      oldQasa: null,
      realQasa: null,
    };
  },
  methods: {
    async getRealQasa() {
      this.$loading(true);
      const URL = "/api/dashboardReports/GetRealQasa";
      this.realQasa = await get(URL);
      this.$loading(false);
    },
    async saveQasaQadima() {
      const URL = "/api/dashboardReports/SaveQasaQadima";
      if (this.oldQasa.enabled == "true") {
        this.oldQasa.enabled = true;
      } else {
        this.oldQasa.enabled = false;
      }
      this.$loading(true);
      await post(URL, this.oldQasa);
      await this.getOldQasa();
      await this.getRealQasa();
      this.$loading(false);
    },
    async getOldQasa() {
      this.$loading(true);
      const URL = "/api/dashboardReports/GetOldQasa";
      this.oldQasa = await get(URL);
      if (this.oldQasa) {
        this.oldQasa.startingDate = this.formatDateAndAssignToInput(
          this.oldQasa.startingDate
        );
      }
      this.$loading(false);
    },
    async loadBuyerCompaniesDebt() {
      this.$loading(true);
      const URL = "/api/dashboardReports/buyerCompaniesDebtDashboard";
      this.buyerCompaniesDebt = await get(URL);
      this.$loading(false);
    },
    async loadCompaniesDebt() {
      this.$loading(true);
      const URL = "/api/dashboardReports/CompaniesDebtDashboard";
      this.companiesDebt = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadBuyerCompaniesDebt();
    await this.loadCompaniesDebt();
    await this.getOldQasa();
    await this.getRealQasa();
  },
};
</script>
