export default {
    methods: {
        config() {
            const user = JSON.parse(localStorage.getItem('user'))
            return {
                headers: {
                    Authorization: "bearer " + user.token.toString(),
                    "Access-Control-Allow-Origin": "http://localhost:8080"
                }
            };
        },
        checkRootAdmin(){
            const user = JSON.parse(localStorage.getItem('user'))
            return user.role == "RootAdmin"
        },
        checkAdmin(){
            const user = JSON.parse(localStorage.getItem('user'))
            return user.role == "Admin"
        },
        checkEmployee(){
            const user = JSON.parse(localStorage.getItem('user'))
            return user.role == "Employee"
        },
        getCurrentUserFullName(){
            const user = JSON.parse(localStorage.getItem('user'))
            return user.fullName
        },
        cleanDate(date) {
            if (date == null) {
                return
            }
            let thisDate = new Date(date);
            return thisDate.toLocaleDateString("en-GB");
        },
        getCurrency(currency) {
            if (currency === 1) {
                return "$"
            } else {
                return "¥"
            }
        },
        formatDateAndAssignToInput(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate
        },
        getTransactionClass(type) {
            if (type == 1) return "badge bg-success"
            if (type == 2) return "badge bg-danger"
            if (type == 3) return "badge bg-dark"
            if (type == 4) return "badge text-danger bg-light"
            return "err"
        },

        openModal(modalId) {
            // eslint-disable-next-line no-undef
            var myModal = new bootstrap.Modal(document.getElementById(modalId), {
                keyboard: false
            })
            myModal.show()
        }
    }
}