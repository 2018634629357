<template>
    <div>
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">{{ $t('OrderContainer.orderDetail') }}</h1>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <form action="" @submit.prevent="addOrderContainer">
                                <div class="row">
                                    <div class="col col-lg-4 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t('OrderContainer.container') }}</label>
                                            <input required type="text" class="form-control mt-1"
                                                :placeholder="$t('OrderContainer.container').toString()"
                                                v-model="orderContainerBody.description">
                                        </div>
                                    </div>
                                    <div class="col col-lg-2 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t('OrderContainer.tones') }}</label>
                                            <input required type="number" step="any" class="form-control mt-1"
                                                :placeholder="$t('OrderContainer.tones').toString()"
                                                v-model.number="orderContainerBody.tones">
                                        </div>
                                    </div>
                                    <div class="col col-lg-2 col-8 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t('OrderContainer.sellPrice') }}</label>
                                            <input required type="number" step="any" class="form-control mt-1"
                                                :placeholder="$t('OrderContainer.sellPrice').toString()"
                                                v-model.number="orderContainerBody.sellPrice">
                                        </div>
                                    </div>
                                    <div class="col col-lg-1 col-4 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t('OrderContainer.currency') }}</label>
                                            <select class="form-control mt-1"
                                                v-model.number="orderContainerBody.sellPriceCurrency">
                                                <option name="" id="" value="1">$</option>
                                                <option name="" id="" value="2">¥</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col col-lg-2 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="">{{ $t('OrderContainer.totalSellPrice') }}</label>
                                            <input disabled v-model.number="orderContainerBody.totalSellPrice" required
                                                type="number" step="any" class="form-control mt-1"
                                                :placeholder="$t('OrderContainer.totalSellPrice').toString()">
                                        </div>
                                    </div>
                                    <div class="col col-lg-1 col-12 mt-1">
                                        <div class="form-group">
                                            <label style="visibility: hidden;" for="">{{ $t('OrderContainer.currency')
                                            }}</label>
                                            <button class="w-100 btn btn-success mt-1" type="submit">{{
                                                orderContainerBody.id > 0 ? $t("Order.edit") : $t("Order.add")
                                            }}</button>
                                        </div>
                                    </div>

                                </div>

                            </form>

                            <div class="totals mt-4">
                               
                                <div class="totalSell d-flex mb-3">
                                    <span class="badge bg-success p-2">{{
                                        $t('OrderContainer.totalSellPrice') }} :</span>
                                    <span class="mx-2" style="font-weight: bold;"> {{ finalTotalSellPrice.toLocaleString()
                                    }}</span>
                                </div>
                               
                            </div>
                            <div class="table-responsive mt-4">
                                <table class="table table-hover my-0">
                                    <thead>
                                        <tr>
                                            <th>{{ $t('OrderContainer.container') }}</th>
                                            <th>{{ $t('OrderContainer.tones') }}</th>
                                            <th>{{ $t('OrderContainer.sellPrice') }}</th>
                                            <th>{{ $t('OrderContainer.totalSellPrice') }}</th>
                                            <th>{{ $t('OrderContainer.data') }}</th>
                                            <th>{{ $t('OrderTbl.actions') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="orderContainers">
                                        <tr v-for="(container) in orderContainers" :key="'order-' + container.id">
                                            <td>{{ container.description }}</td>
                                            <td>{{ container.tones }}</td>
                                            <td>{{ getCurrency(container.sellPriceCurrency) }} {{
                                                container.sellPrice.toLocaleString() }}</td>
                                            <td>{{ getCurrency(container.sellPriceCurrency) }} {{
                                                container.totalSellPrice.toLocaleString() }}</td>
                                            <td>{{ cleanDate(container.creationDate) }}</td>
                                            <td>
                                                <button @click="assignFields(container)" class="btn btn-warning mx-1 my-1 "><i
                                                        class="fa fa-pen"></i></button>
                                                <button @click="deleteRow(container.id)" class="btn btn-danger mx-1"><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { post, get, del } from '@/api'
export default {
    name: "CreateOrderContainer",
    data() {
        return {
            orderContainerBody: {
                id: 0,
                orderId: parseInt(this.$route.params.orderId),
                description: null,
                tones: 0.0,
                sellPrice: 0.0,
                sellPriceCurrency: 1,
                get totalSellPrice() {
                    return `${this.tones * this.sellPrice}`;
                },
            },

            orderContainers: null,
            finalTotalSellPrice: 0.0,
            orderBuyPriceSum: 0.0
        }
    },
    methods: {
        assignFields(passedObj) {
            console.log(passedObj);
            this.orderContainerBody = {
                id: passedObj.id,
                orderId: parseInt(this.$route.params.orderId),
                description: passedObj.description,
                tones: passedObj.tones,
                sellPrice: passedObj.sellPrice,
                sellPriceCurrency: passedObj.sellPriceCurrency,
                get totalSellPrice() {
                    return `${this.tones * this.sellPrice}`;
                },
            }
        },
        async deleteRow(id) {
            this.$confirm(
                this.$t("messages.deleteConfirmation"),
                "Question",
                "question"
            )
                .then(async () => {
                    this.$loading(true);
                    const URL = `/API/OrderContainers?id=${id}`
                    try {
                       await del(URL);
                       await this.getOrderContainers();
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.$loading(false);
                    }


                })
                .catch(() => {
                    console.log("OK not selected.");
                });
        },
        async addOrderContainer() {
            this.$loading(true)
            const URL = "/API/OrderContainers"
            try {
                await post(URL, this.orderContainerBody);
                await this.getOrderContainers();
                this.$toast.open({
                    message: "Data added",
                    type: 'success',
                });
                this.orderContainerBody = {
                    id: 0,
                    orderId: parseInt(this.$route.params.orderId),
                    description: null,
                    tones: 0.0,
                    sellPrice: 0.0,
                    sellPriceCurrency: 1,
                    get totalSellPrice() {
                        return `${this.tones * this.sellPrice}`;
                    },
                }
            } catch (error) {
                console.log(error);
                this.$toast.open({
                    message: "An Error Occured",
                    type: 'error',
                });
            } finally {
                this.$loading(false)
            }
        },
        async getOrderContainers() {
            this.finalTotalSellPrice = 0.0;
            this.$loading(true)
            const URL = `/API/OrderContainers?orderId=${parseInt(this.$route.params.orderId)}`
            try {
                this.orderContainers = await get(URL);
                if (this.orderContainers) {
                    this.finalTotalSellPrice = this.orderContainers.reduce((total, container) => total + container.totalSellPrice, 0);
                }
            } catch (error) {

                this.$toast.open({
                    message: "An Error Occured",
                    type: 'error',
                });
            } finally {
                this.$loading(false)
            }
        },
        async getOrderBuyPriceSum() {
            this.$loading(true)
            const URL = `/getBuyPriceSum?orderId=${parseInt(this.$route.params.orderId)}`
            try {
                this.orderBuyPriceSum = await get(URL);
            } catch (error) {

                this.$toast.open({
                    message: "An Error Occured",
                    type: 'error',
                });
            } finally {
                this.$loading(false)
            }
        }


    },
    async mounted() {
        await this.getOrderContainers();
    
    },
}
</script>