<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("commercialInvoice.details") }}</h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end my-2">
                <button
                  class="btn btn-success btn-sm rounded mx-1"
                  @click="addToDetail()"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
              <span class="badge bg-primary p-2 my-1"> {{ $t("total") }} </span>
              {{ finalTotal.toLocaleString() }}
              <div class="table-responsive" v-if="details && details.length > 0">
                <div class="table-container" style="overflow-x: auto">
                  <table class="table">
                    <thead>
                      <tr class="table-head-color text-light">
                        <th scope="col">#</th>
                        <th scope="col">{{ $t("purchase.type") }}</th>
                        <th scope="col" v-if="checkGlass()">
                          {{ $t("purchase.quality") }}
                        </th>
                        <th scope="col" v-if="checkGlass()">
                          {{ $t("purchase.thickness") }}
                        </th>
                        <th scope="col" v-if="checkGlass()">
                          {{ $t("purchase.dimensionsW") }}
                        </th>
                        <th scope="col" v-if="checkGlass()">
                          {{ $t("purchase.dimensionsH") }}
                        </th>
                        <th scope="col" v-if="checkGlass()">
                          {{ $t("purchase.noOfCrates") }}
                        </th>
                        <th scope="col" v-if="checkGlass()">
                          {{ $t("purchase.sheets") }}
                        </th>

                        <th scope="col">{{ $t("purchase.containerNo") }}</th>
                        <th scope="col">
                          {{
                            checkGlass() ? $t("purchase.totalSQM") : $t("purchase.tones")
                          }}
                        </th>
                        <th scope="col">
                          {{
                            checkGlass()
                              ? $t("purchase.pricePerSQM")
                              : $t("purchase.pricePerTone")
                          }}
                        </th>
                        <th scope="col">{{ $t("purchase.totalAmount") }}</th>
                        <td scope="col"></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in details" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>
                          <vue-multiselect
                            style="width: 400px"
                            v-model="item.type"
                            :options="availableItems"
                            placeholder="Select one"
                            label="name"
                            track-by="name"
                          ></vue-multiselect>
                        </td>
                        <td v-if="checkGlass()">
                          <select class="form-control" v-model="item.quality"  style="width: 50px">
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                          </select>
                        </td>
                        <td v-if="checkGlass()">
                          <input style="width: 100px" type="number" step="any" class="form-control" v-model="item.thinkness">
                          <!-- <select class="form-control" v-model="item.thinkness">
                            <option value="1">1 mm</option>
                            <option value="2">2 mm</option>
                            <option value="3">3 mm</option>
                            <option value="4">4 mm</option>
                            <option value="5">5 mm</option>
                            <option value="6">6 mm</option>
                            <option value="7">7 mm</option>
                            <option value="8">8 mm</option>
                            <option value="9">9 mm</option>
                            <option value="10">10 mm</option>
                            <option value="11">11 mm</option>
                            <option value="12">12 mm</option>
                          </select> -->
                        </td>
                        <td v-if="checkGlass()">
                          <input
                            style="width: 100px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.dimensionsW')"
                            v-model.number="item.dimensionW"
                          />
                        </td>
                        <td v-if="checkGlass()">
                          <input
                            style="width: 100px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.dimensionsH')"
                            v-model.number="item.dimensionH"
                          />
                        </td>
                        <td v-if="checkGlass()">
                          <input
                            style="width: 100px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.noOfCrates')"
                            v-model.number="item.numberOfCrate"
                          />
                        </td>
                        <td v-if="checkGlass()">
                          <input
                          style="width: 100px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.sheets')"
                            v-model.number="item.sheetsPerCrate"
                          />
                        </td>

                        <td>
                          <input
                            style="width: 150px"
                            class="form-control"
                            :placeholder="$t('purchase.containerNo')"
                            v-model="item.containerNo"
                          />
                        </td>
                        <td>
                          <input
                            v-if="checkGlass()"
                            style="width: 150px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.totalSQM')"
                            :value="totalSQMPerContainer(item).toFixed(2)"
                            disabled
                          />
                          <input
                            v-else
                            style="width: 150px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.tones')"
                            v-model="item.numberOfTones"
                          />
                        </td>
                        <td>
                          <div class="d-flex">
                            <input
                              v-if="checkGlass()"
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.pricePerSQM')"
                              v-model.number="item.pricePerSQM"
                            />
                            <input
                              v-else
                              style="width: 100px"
                              type="number"
                              step="any"
                              class="form-control"
                              :placeholder="$t('purchase.pricePerTone')"
                              v-model.number="item.pricePerTone"
                            />
                            <select
                              class="form-control mx-1"
                              style="width: 50px"
                              v-model.number="item.currencyType"
                            >
                              <option value="1">$</option>
                              <option value="2">¥</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <input
                            style="width: 150px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.totalAmount')"
                            :value="totalAmount(item).toFixed(2)"
                            disabled
                          />
                        </td>
                        <td>
                          <button
                            class="btn btn-danger btn-sm rounded"
                            @click="removeItem(index)"
                            v-if="item.id == 0"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                          <button
                            class="btn btn-danger btn-sm rounded"
                            @click="deleteDetail(item.id)"
                            v-else
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="row justify-content-end my-3"
                v-if="details && details.length > 0"
              >
                <div class="col col-lg-1 col-12 d-flex justify-content-end">
                  <button class="btn btn-success rounded" @click="saveDetails()">
                    {{ $t("purchase.save") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post, del } from "@/api";
export default {
  name: "CommercialInvoiceDetails",
  data() {
    return {
      orderProductType: null,
      detailBody: {
        id: 0,
        type: null,
        itemId: 0,
        quality: null,
        thinkness: null,
        dimensionW: 0.0,
        dimensionH: 0.0,
        sheetsPerCrate: 0,
        numberOfCrate: 0,
        numberOfContainers: 0,
        containerNo: null,
        currencyType: 1,
        pricePerSQM: 0.0,
        totalSQMPerContainer: 0.0,
        totalAmount: 0.0,
        orderCommericalInvoiceId: parseInt(this.$route.params.invoiceId),
        productType: null,
        numberOfTones: 0.0,
        pricePerTone: 0.0,
      },
      details: [],
      availableItems: [],
    };
  },
  computed: {
    totalSQMPerContainer() {
      return (passedData) => {
        const { dimensionW, dimensionH, numberOfCrate, sheetsPerCrate } = passedData;
        return (dimensionW / 1000) * (dimensionH / 1000) * numberOfCrate * sheetsPerCrate;
      };
    },
    totalAmount() {
      return (passedData) => {
        if (this.orderProductType == "Glass") {
          const totalSQMPerContainer = this.totalSQMPerContainer(passedData);
          const { pricePerSQM } = passedData;
          return totalSQMPerContainer * pricePerSQM;
        } else {
          const { pricePerTone, numberOfTones } = passedData;
          return pricePerTone * numberOfTones;
        }
      };
    },
    finalTotal() {
      return this.details.reduce((sum, detail) => {
        const totalAmount = this.totalAmount(detail);
        return sum + totalAmount;
      }, 0);
    },
  },
  methods: {
    checkGlass() {
      return this.orderProductType && this.orderProductType == "Glass";
    },
    async loadItems() {
      this.$loading(true);
      const URL = `/api/items?page=1&size=10000000&isForMuliSelect=true&productType=${this.orderProductType}`;
      var res = await get(URL);
      this.availableItems = res.items;
      this.$loading(false);
    },
    resetProperties() {
      this.detailBody = {
        id: 0,
        type: null,
        itemId: 0,
        quality: null,
        thinkness: null,
        dimensionW: 0.0,
        dimensionH: 0.0,
        sheetsPerCrate: 0,
        numberOfCrate: 0,
        numberOfContainers: 0,
        currencyType: 1,
        pricePerSQM: 0.0,
        totalSQMPerContainer: 0.0,
        totalAmount: 0.0,
        orderCommericalInvoiceId: parseInt(this.$route.params.invoiceId),
        productType: null,
        numberOfTones: 0.0,
        pricePerTone: 0.0,
      };
    },
    addToDetail() {
      this.details.push(this.detailBody);
      this.resetProperties();
    },
    async getProductType() {
      const URL = `/api/orders/orderProductType?orderId=${this.$route.params.orderId}`;
      this.orderProductType = await get(URL);
    },
    async getDetails() {
      this.$loading(true);
      const URL = `/api/commercialInvoiceDetails?invoiceId=${this.$route.params.invoiceId}`;
      this.details = await get(URL);
      this.details.forEach((detail) => {
        detail.type = {
          id: detail.itemId,
          name: detail.type,
          productType: detail.productType,
        };
      });
      this.$loading(false);
    },
    removeItem(index) {
      this.details.splice(index, 1);
    },
    async deleteDetail(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/commercialInvoiceDetails?id=${id}`;
          await del(URL);
          await this.getDetails();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    async saveDetails() {
      this.$loading(true);
      this.details.forEach((detail) => {
        detail.totalSQMPerContainer = this.totalSQMPerContainer(detail);
        detail.totalAmount = this.totalAmount(detail);
        detail.itemId = detail.type.id;
        detail.productType = detail.type.productType;
        detail.type = detail.type.name;
      });

      const URL = "/api/commercialInvoiceDetails";
      await post(URL, this.details);
      await this.getDetails();
      this.$loading(false);
    },
  },
  async mounted() {
    await this.getProductType();
    await this.loadItems();
    await this.getDetails();
  },
};
</script>
