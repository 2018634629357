<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("Sale.list") }}</h1>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex d-lg-flex justify-content-between my-2">
                <router-link class="btn btn-success" to="/Sales/Create">
                  {{ $t("OrderTbl.addNew") }}
                </router-link>
              </div>
              <div class="table-responsive rounded">
                <table class="table table-hover my-0">
                  <thead style="background-color: #222e3c">
                    <tr class="text-light text-center">
                      <th>{{ $t("Sale.SaleNo") }}</th>
                      <th>{{ $t("Sale.FromPurchaseNo") }}</th>
                      <th>{{ $t("Sale.Date") }}</th>
                      <th>{{ $t("Sale.ToCompany") }}</th>
                      <th>{{ $t("Sale.fromWarehouse") }}</th>
                      <th>{{ $t("Sale.TotalAmount") }}</th>
                      <th>{{ $t("Sale.TotalPaid") }}</th>
                      <th>{{ $t("Sale.Remaining") }}</th>
                      <th>{{ $t("purchase.deliveryStatus") }}</th>
                      <th>{{ $t("purchase.portName") }}</th>
                      <th>{{ $t("Sale.bookingNo") }}</th>
                      <th>{{ $t("Sale.containerNo") }}</th>
                      
                      <!-- <th>{{ $t("Sale.status") }}</th> -->
                      <th>{{ $t("OrderTbl.actions") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="sales && sales.items">
                    <tr
                      class="text-center"
                      v-for="sale in sales.items"
                      :key="'sale-' + sale.id"
                    >
                      <td>
                        <router-link :to="`/Sales/Create/${sale.id}`">{{
                          sale.saleNo
                        }}</router-link>
                      </td>
                      <td>{{ sale.purchaseNo }}</td>
                      <td>{{ cleanDate(sale.creationDate) }}</td>

                      <td>{{ sale.buyerCompanyName }}</td>
                      <td>{{ sale.warehouseName }}</td>
                      <td>
                        {{ getCurrency(sale.currencyType) }}
                        {{ sale.totalAmount.toLocaleString() }}
                      </td>
                      <td>
                        <router-link
                          :to="`/Sales/Payments/${sale.id}/${sale.buyerCompanyId}/${sale.currencyType}/${sale.buyerCompanyName}/${sale.totalAmount}/${sale.saleNo}`"
                        >
                          {{ getCurrency(sale.currencyType) }}
                          {{ sale.totalPaid.toLocaleString() }}</router-link
                        >
                      </td>
                      <td>
                        {{ getCurrency(sale.currencyType) }}
                        {{ sale.remaining.toLocaleString() }}
                      </td>
                      <td>{{ sale.deliveryStatus }}</td>
                      <td>{{ sale.portName }}</td>
                      <!-- <td>
                        <span class="badge bg-danger" v-if="sale.state == 'Not Paid'">{{
                          sale.state
                        }}</span>
                        <span class="badge bg-success" v-if="sale.state == 'Paid'">{{
                          sale.state
                        }}</span>
                        <span
                          class="badge bg-warning text-dark"
                          v-if="sale.state == 'Partially Paid'"
                          >{{ sale.state }}</span
                        >
                      </td> -->
                      <td>{{sale.bookingNo  }}</td>
                      <td>{{sale.containerNo  }}</td>
                      <td>
                        <router-link
                          :to="`/Prints/Invoice/${sale.id}`"
                          type="button"
                          class="btn btn-success btn-sm rounded mx-1 my-1"
                        >
                          <i class="fa fa-print"></i>
                        </router-link>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm rounded mx-1 my-1"
                          @click="deleteSale(sale.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
                :pageIndex="pageIndex"
                :totalPages="totalPages"
                :onPageChange="handlePageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, del } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
export default {
  name: "SalesList",
  components: {
    Pagination,
  },
  data() {
    return {
      sales: null,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  computed: {
    totalItems() {
      return this.sales ? this.sales.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.sales.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadSales();
    },
    async deleteSale(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/sales/delete?saleId=${id}`;
          await del(URL);
          await this.loadSales();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    async loadSales() {
      this.$loading(true);
      const URL = `/api/sales?page=${this.pageIndex}&size=${this.pageSize}`;
      this.sales = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadSales();
  },
};
</script>
