<template>
  <div>
    <div class="container-fluid p-0">
      <!-- Create Order Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("purchase.newPurchase") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form class="form" @submit.prevent="addNewOrder">
              <div class="modal-body">
                <div class="form-group my-2">
                  <label for="">{{ $t("purchase.Origin") }}</label>
                  <select
                    class="form-control mt-1"
                    v-model="selectedCountry"
                    @change="countryChanged()"
                  >
                    <option
                      v-for="country in countries"
                      :key="'country-' + country.id"
                      :value="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group my-2">
                  <label for="">{{ $t("purchase.company") }}</label>
                  <select class="form-control mt-1" v-model="purchaseBody.companyId">
                    <option
                      v-for="company in companies"
                      :key="'company-' + company.id"
                      :value="company.id"
                    >
                      {{ company.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group my-2">
                  <label for="">{{ $t("purchase.no") }}</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    v-model="purchaseBody.purchaseNo"
                    required
                  />
                </div>
                <div class="form-group my-2">
                  <label for="">{{ $t("purchase.totalAmount") }}</label>
                  <input
                    type="number"
                    step="any"
                    class="form-control mt-1"
                    v-model.number="purchaseBody.buyPrice"
                  />
                </div>
                <div class="form-group my-2">
                  <label for="">{{ $t("purchase.currency") }}</label>
                  <select
                    class="form-control mt-1"
                    v-model.number="purchaseBody.buyPriceCurrency"
                  >
                    <option value="1">$</option>
                    <option value="2">¥</option>
                  </select>
                </div>
                <div class="form-group my-2">
                  <label for="">{{ $t("purchase.deliveryStatus") }}</label>
                  <select class="form-control mt-1" v-model="purchaseBody.deliveryStatus">
                    <option value="FOB">FOB</option>
                    <option value="EXW ">EXW</option>
                    <option value="CIF ">CIF</option>
                  </select>
                </div>
                <div class="form-group my-2">
                  <label for="">{{ $t("purchase.portName") }}</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    v-model="purchaseBody.portName"
                  />
                </div>
                <div class="form-group my-2">
                  <label for="">{{ $t("purchase.productType") }}</label>
                  <select
                    required
                    class="form-control mt-1"
                    v-model="purchaseBody.productType"
                  >
                    <option value="Glass">Glass</option>
                    <option value="Raw Material ">Raw Material</option>
                  </select>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="close-btn"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {{ $t("purchase.close") }}
                </button>
                <button type="submit" class="btn btn-primary">
                  {{ $t("purchase.save") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <h1 class="h3 mb-3">{{ $t("Orders.List") }}</h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header d-lg-flex justify-content-between">
              <button
                @click="resetProperties()"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                class="btn btn-success mt-1"
              >
                {{ $t("OrderTbl.addNew") }}
              </button>
              <div class="col col-lg-3 col-12 mt-1">
                <input type="text" class="form-control" placeholder="search here" />
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive rounded">
                <table class="table table-hover my-0">
                  <thead style="background-color: #222e3c">
                    <tr class="text-light text-center">
                      <th>{{ $t("OrderTbl.purchaseNO") }}</th>
                      <th>{{ $t("OrderTbl.origin") }}</th>
                      <th>{{ $t("OrderTbl.totalPurchase") }}</th>
                      <th>{{ $t("OrderTbl.totalPaid") }}</th>
                      <th>{{ $t("OrderTbl.remaining") }}</th>
                      <th>{{ $t("OrderTbl.commercialInvoices") }}</th>
                      <th>{{ $t("OrderTbl.expense") }}</th>
                      <th>{{ $t("OrderTbl.ProductType") }}</th>
                      <th>{{ $t("OrderTbl.DeliveryStatus") }}</th>
                      <th>{{ $t("OrderTbl.PortName") }}</th>

                      <th>{{ $t("OrderTbl.date") }}</th>
                      <th>{{ $t("OrderTbl.actions") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="orders && orders.items">
                    <tr
                      class="text-center"
                      v-for="order in orders.items"
                      :key="'order-' + order.id"
                    >
                      <td>
                        <router-link :to="`/Orders/Details/${order.id}`">{{
                          order.purchaseNo
                        }}</router-link>
                      </td>
                      <td>{{ order.companyName }} - {{ order.countryName }}</td>
                      <td>{{ getCurrency(order.buyPriceCurrency) }} {{ order.totalPurchase.toLocaleString() }}</td>
                      <td>
                        <router-link
                          :to="`/Orders/Paids/${order.id}/${order.companyName}/${order.companyId}/${order.totalPurchase}`"
                          >{{ getCurrency(order.buyPriceCurrency) }} {{ order.totalPaid.toLocaleString() }}</router-link
                        >
                      </td>
                      <td> {{ getCurrency(order.buyPriceCurrency) }} {{ order.totalRemaining.toLocaleString() }}</td>
                      <td>
                        <router-link :to="`/CommercialInvoices/List/${order.id}`">{{
                          order.commercialInvoiceCount
                        }}</router-link>
                      </td>
                      <td>
                        <router-link :to="`/OrderExpenses/Create/${order.id}`">
                          <span class="d-block my-1"
                            >${{ order.totalExpenseDollar.toLocaleString() }}</span
                          >
                          <span class="d-block"
                            >¥{{ order.totalExpenseYuan.toLocaleString() }}</span
                          >
                        </router-link>
                      </td>

                      <td>{{ order.productType }}</td>
                      <td>{{ order.deliveryStatus }}</td>
                      <td>{{ order.portName }}</td>
                      <td>{{ cleanDate(order.creationDate) }}</td>
                      <td>
                        <router-link
                        v-if="checkRootAdmin() || checkAdmin()"
                          :to="`/Orders/OrderProfit/${order.purchaseNo}/${order.id}`"
                          class="btn btn-success btn-sm rounded my-1 mx-1"
                        >
                          <i class="fa fa-chart-line"></i>
                        </router-link>
                        <button
                          class="btn btn-secondary btn-sm rounded my-1 mx-1"
                          @click="updateFields(order)"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        <button
                        v-if="checkRootAdmin() || checkAdmin()"
                          class="btn btn-danger mx-1 my-1 btn-sm rounded"
                          @click="deleteRow(order.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
                :pageIndex="pageIndex"
                :totalPages="totalPages"
                :onPageChange="handlePageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/PaginationComponent.vue";
import { get, post, del } from "@/api";
import $ from "jquery";

export default {
  name: "OrdersList",
  components: {
    Pagination,
  },
  data() {
    return {
      orders: null,
      seachText: null,
      pageIndex: 1,
      pageSize: 10,

      purchaseBody: {
        id: 0,
        companyId: 0,
        purchaseNo: null,
        deliveryStatus: null,
        portName: null,
        buyPrice: null,
        buyPriceCurrency: 1,
        productType: null,
      },
      selectedCountry: null,

      countries: null,
      companies: null,
    };
  },
  computed: {
    totalItems() {
      return this.orders ? this.orders.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.orders.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    resetProperties() {
      this.purchaseBody = {
        id: 0,
        companyId: 0,
        purchaseNo: null,
        deliveryStatus: null,
        portName: null,
        buyPrice: null,
        buyPriceCurrency: 1,
        productType: null,
      };
    },
    async deleteRow(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/orders/deleteOrder?id=${id}`;
          await del(URL, true);
          await this.loadOrders();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    async updateFields(order) {
      this.selectedCountry = order.country.id;
      await this.countryChanged();
      this.purchaseBody = {
        id: order.id,
        companyId: order.companyId,
        purchaseNo: order.purchaseNo,
        deliveryStatus: order.deliveryStatus,
        portName: order.portName,
        buyPrice: order.buyPrice,
        buyPriceCurrency: order.buyPriceCurrency,
        productType: order.productType,
      };
      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"), {
        keyboard: false,
      });
      myModal.show();
    },
    async addNewOrder() {
      this.$loading(true);
      const URL = "/API/Orders";
      await post(URL, this.purchaseBody);
      $("#close-btn").click();
      this.resetProperties();
      await this.loadOrders();
    },
    handlePageChange(newPage) {
      this.pageIndex = newPage;
      this.loadOrders();
    },
    async loadOrders() {
      this.$loading(true);
      const URL = `/API/Orders?page=${this.pageIndex}&size=${this.pageSize}`;
      try {
        this.orders = await get(URL);
      } catch (error) {
        console.log(error);
      } finally {
        this.$loading(false);
      }
    },
    async loadCountries() {
      this.$loading(true);
      const URL = "/getCountriesList";
      this.countries = await get(URL);

      this.$loading(false);
    },
    async loadCompanies() {
      this.$loading(true);
      const URL = `/api/companies?countryId=${this.selectedCountry}&page=1&size=100000&excludeDebs=true`;
      const res = await get(URL);
      this.companies = res.items

      this.$loading(false);
    },
    async countryChanged() {
      await this.loadCompanies();
    },
  },
  async created() {
    await this.loadOrders();
    await this.loadCountries();
  },
};
</script>
