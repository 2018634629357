import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    refreshData: false
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    LOGOUT(state) {
      state.user = null
      localStorage.removeItem('user')
    },
    SET_REFRESH_DATA(state, value) {
      state.refreshData = value
    }
  },
  getters: {
    isLoggedIn: state => !!state.user,
    getCurrentUserInfo: state => state.user
  },
  actions: {
    setRefreshData({ commit }, value) {
      commit('SET_REFRESH_DATA', value)
    },
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    logout({ commit }) {
      commit('LOGOUT')
    },
    init({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user) {
        commit('SET_USER', user)
      }
    },
    
  }
})
