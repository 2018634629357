<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("orderDetails") }}</h1>
      <div class="row">
        <div class="col-12">
          <CreateAttachmentModal
            type="2"
            :entity-id="$route.params.orderId"
            :onAttachmentCreation="handleAfterAddAttachment"
          />
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end my-2">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#CreateAttachmentModal"
                  class="btn btn-warning btn-sm rounded text-dark mx-1"
                >
                  {{ $t("newAttachment") }}
                </button>
                <!-- <button
                  class="btn btn-success btn-sm rounded mx-1"
                  @click="addToDetail()"
                >
                  <i class="fa fa-plus"></i>
                </button> -->
              </div>

              <div class="table-responsive" v-if="details && details.length > 0">
                <div class="table-container" style="overflow-x: auto">
                  <table class="table">
                    <thead>
                      <tr class="table-head-color text-light">
                        <th scope="col">#</th>
                        <th scope="col">{{ $t("purchase.type") }}</th>
                        <th scope="col">{{ $t("purchase.quality") }}</th>
                        <th scope="col">{{ $t("purchase.thickness") }}</th>
                        <th scope="col">{{ $t("purchase.dimensionsW") }}</th>
                        <th scope="col">{{ $t("purchase.dimensionsH") }}</th>
                        <th scope="col">{{ $t("purchase.sheets") }}</th>
                        <th scope="col">{{ $t("purchase.noOfCrates") }}</th>
                        <th scope="col">{{ $t("purchase.noOfContainers") }}</th>
                        <th scope="col">{{ $t("purchase.pricePerSQM") }}</th>
                        <th scope="col">{{ $t("purchase.totalSQM") }}</th>
                        <th scope="col">{{ $t("purchase.totalAmount") }}</th>
                        <td scope="col"></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in details" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>
                          <input
                            style="width: 250px"
                            type="text"
                            class="form-control"
                            :placeholder="$t('purchase.type')"
                            v-model="item.type"
                          />
                        </td>
                        <td>
                          <select class="form-control" v-model="item.quality">
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                          </select>
                        </td>
                        <td>
                          <select class="form-control" v-model="item.thinkness">
                            <option value="1">1 mm</option>
                            <option value="2">2 mm</option>
                            <option value="3">3 mm</option>
                            <option value="4">4 mm</option>
                            <option value="5">5 mm</option>
                            <option value="6">6 mm</option>
                            <option value="7">7 mm</option>
                            <option value="8">8 mm</option>
                            <option value="9">9 mm</option>
                            <option value="10">10 mm</option>
                            <option value="11">11 mm</option>
                            <option value="12">12 mm</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.dimensionsW')"
                            v-model.number="item.dimensionW"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.dimensionsH')"
                            v-model.number="item.dimensionH"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.sheets')"
                            v-model.number="item.sheetsPerCrate"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.noOfCrates')"
                            v-model.number="item.numberOfCrate"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.noOfContainers')"
                            v-model.number="item.numberOfContainers"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.pricePerSQM')"
                            v-model.number="item.pricePerSQM"
                          />
                        </td>
                        <td>
                          <input
                            style="width: 150px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.totalSQM')"
                            :value="totalSQMPerContainer(item).toFixed(2)"
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            style="width: 150px"
                            type="number"
                            step="any"
                            class="form-control"
                            :placeholder="$t('purchase.totalAmount')"
                            :value="totalAmount(item).toFixed(2)"
                            disabled
                          />
                        </td>
                        <td>
                          <button
                            class="btn btn-danger btn-sm rounded"
                            @click="removeItem(index)"
                            v-if="item.id == 0"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                          <button
                            class="btn btn-danger btn-sm rounded"
                            @click="deleteDetail(item.id)"
                            v-else
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                      <tr style="text-align: right">
                        <th colspan="12">
                          <span class="badge bg-primary p-2"> {{ $t("total") }} </span>
                          $ {{ finalTotal.toLocaleString() }}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="row justify-content-end my-3"
                v-if="details && details.length > 0"
              >
                <div class="col col-lg-1 col-12 d-flex justify-content-end">
                  <button class="btn btn-success rounded" @click="saveDetails()">
                    {{ $t("purchase.save") }}
                  </button>
                </div>
              </div>
              <AttachmentList
                v-if="attachments"
                :attachments="attachments"
                :onAttachmentDelete="handleAfterAddAttachment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get, del } from "@/api";
import CreateAttachmentModal from "@/components/CreateAttachmentModal.vue";
import AttachmentList from "@/components/AttachmentList.vue";
export default {
  name: "OrderDetails",
  components: {
    CreateAttachmentModal,
    AttachmentList,
  },
  data() {
    return {
      detailBody: {
        id: 0,
        type: null,
        quality: null,
        thinkness: null,
        dimensionW: 0.0,
        dimensionH: 0.0,
        sheetsPerCrate: 0,
        numberOfCrate: 0,
        numberOfContainers: 0,
        pricePerSQM: 0.0,
        totalSQMPerContainer: 0.0,
        totalAmount: 0.0,
        orderId: parseInt(this.$route.params.orderId),
      },
      details: [],
      attachments: null,
    };
  },
  computed: {
    totalSQMPerContainer() {
      return (passedData) => {
        const { dimensionW, dimensionH, numberOfCrate, sheetsPerCrate } = passedData;
        return (dimensionW / 1000) * (dimensionH / 1000) * numberOfCrate * sheetsPerCrate;
      };
    },
    totalAmount() {
      return (passedData) => {
        const totalSQMPerContainer = this.totalSQMPerContainer(passedData);
        const { pricePerSQM, numberOfContainers } = passedData;
        return totalSQMPerContainer * pricePerSQM * numberOfContainers;
      };
    },
    finalTotal() {
      return this.details.reduce((sum, detail) => {
        const totalAmount = this.totalAmount(detail);
        return sum + totalAmount;
      }, 0);
    },
  },
  methods: {
    async handleAfterAddAttachment() {
      await this.loadAttachments();
    },
    removeItem(index) {
      this.details.splice(index, 1);
    },
    addToDetail() {
      this.details.push(this.detailBody);
      this.detailBody = {
        id: 0,
        type: null,
        quality: null,
        thinkness: null,
        dimensionW: 0.0,
        dimensionH: 0.0,
        sheetsPerCrate: 0,
        numberOfCrate: 0,
        numberOfContainers: 0,
        pricePerSQM: 0.0,
        totalSQMPerContainer: 0.0,
        totalAmount: 0.0,
        orderId: parseInt(this.$route.params.orderId),
      };
    },
    async getDetails() {
      this.$loading(true);
      const URL = `/api/orderDetails?orderId=${this.$route.params.orderId}`;
      this.details = await get(URL, this.details);
      this.$loading(false);
    },
    async deleteDetail(id) {
      this.$loading(true);
      const URL = `/api/orderDetails?id=${id}`;
      await del(URL);
      await this.getDetails();
      this.$loading(false);
    },
    async saveDetails() {
      this.$loading(true);
      this.details.forEach((detail) => {
        detail.totalSQMPerContainer = this.totalSQMPerContainer(detail);
        detail.totalAmount = this.totalAmount(detail);
      });

      const URL = "/api/orderDetails";
      await post(URL, this.details);
      await this.getDetails();
      this.$loading(false);
    },

    async loadAttachments() {
      this.$loading(true);
      var URL = `/api/attachments?entityId=${this.$route.params.orderId}&type=2`;
      this.attachments = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.getDetails();
    await this.loadAttachments();
  },
};
</script>
