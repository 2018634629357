<template>
  <div class="row">
    <AttachmentModal :selected-attachment="selectedAttachment" />
    <div
      class="col col-lg-3 col-12 mt-3"
      v-for="attachment in attachments"
      :key="'a' + attachment.id"
    >
      <div
        style="height: 100%"
        class="main-attachment-card card shadow d-flex align-items-center border-dark"
        v-if="attachment.imageLink"
      >
        <div class="attachment-del-container p-2 text-light rounded">
          <button @click="deleteRow(attachment.id)" class="btn btn-outline-danger">
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <div class="card-body p-1 mt-2 d-flex align-items-center ">
          <div class="contentss">
            <span class="d-block">
              <a
                v-if="attachment.imageLink.split('.')[1] === 'pdf'"
                role="button"
                @click="selectedAttachment = attachment"
                data-bs-toggle="modal"
                data-bs-target="#ViewAttachmentModal"
              >
                <i
                  class="fa fa-file-pdf text-danger text-center d-block m-auto"
                  style="font-size: 40px"
                ></i>
              </a>

              <a
                v-else
                @click="selectedAttachment = attachment"
                role="button"
                data-bs-toggle="modal"
                data-bs-target="#ViewAttachmentModal"
              >
                <img
                  class="d-block"
                  :src="$hostname + attachment.imageLink"
                  alt=""
                  style="width: 100px; height: auto"
                />
              </a>
            </span>
            <p class="my-3">{{ attachment.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AttachmentModal from "./AttachmentModal.vue";
import { del } from "@/api";
export default {
  name: "AttachmentList",
  components: {
    AttachmentModal,
  },
  data() {
    return {
      selectedAttachment: null,
    };
  },
  props: {
    attachments: {
      type: Array,
      required: true,
    },
    onAttachmentDelete: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async deleteRow(id) {
      this.$confirm(this.$t("messages.deleteConfirmation"), "Question", "question")
        .then(async () => {
          this.$loading(true);
          const URL = `/API/attachments?id=${id}`;
          await del(URL);
          this.onAttachmentDelete();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
  },
  mounted() {},
};
</script>
