<template>
    <div>
        <div class="container d-flex flex-column">
            <div class="row vh-100">
                <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                    <div class="d-table-cell align-middle">
                        <div class="text-center mt-4">
                            <h1 class="h2">Welcome back</h1>
                            <p class="lead">
                                Sign in to your account to continue
                            </p>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="m-sm-4">
                                    <form @submit.prevent="login()">
                                        <div class="mb-3">
                                            <label class="form-label">Email</label>
                                            <input class="form-control form-control-lg" placeholder="Enter your email"
                                                v-model="form.username" required />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Password</label>
                                            <input class="form-control form-control-lg" type="password" name="password"
                                                v-model="form.password" required placeholder="Enter your password" />
                                            <small>
                                                <a href="index.html">Forgot password?</a>
                                            </small>
                                        </div>
                                        <div>
                                            <label class="form-check">
                                                <input class="form-check-input" type="checkbox" value="remember-me"
                                                    name="remember-me" checked>
                                                <span class="form-check-label">
                                                    Remember me next time
                                                </span>
                                            </label>
                                        </div>
                                        <div class="text-center mt-3">
                                            <button type="submit" class="btn btn-lg btn-primary">Sign in</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { post } from '../api'
export default {
    name: "LoginView",
    computed: {
    ...mapGetters(['isLoggedIn', 'user']),
  },
    data() {
        return {
            form: {
                username: '',
                password: ''
            }
        }
    },
    methods: {
        ...mapActions(['setUser', 'logout']),
        async login() {
            this.$loading(true);
            const URL = `/Api/Users/Authenticate?username=${this.form.username}&Password=${this.form.password}`
            try {
                const response = await post(URL)
                console.log(response);
                if (response) {
                  this.setUser(response);
                    this.$toast.open({
                        message: "Logined Successfully",
                        type: 'success',
                    });
                    location.reload();
                   this.$router.push("/")
                } else {
                    this.$toast.open({
                        message: "Credential Error happened",
                        type: 'error',
                    });
                }
            } catch (error) {
                this.$toast.open({
                    message: "Credential Error happened",
                    type: 'error',
                });

            } finally {
                this.$loading(false);
            }
        }
    },

    mounted() {
        if (localStorage.getItem('user')) {
            this.$router.push("/")
        }
    },
}
</script>