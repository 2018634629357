<style scoped>
.font-bold {
    font-weight: bold;
}

.bordered {
    border: 1px solid gray;
    padding: 10px;
}

.padding-right {
    padding-right: 15px;
}
</style>
<template>
    <div>
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">{{ $t('Order.orderDetail') }}</h1>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body" v-if="orderDetail">
                            <AttachmentModal :selectedAttachment="selectedAttachment" />
                            <div class="col col-lg-4">
                                <div class="d-flex justify-content-between">
                                    <span class="badge bg-primary p-2">{{
                                        $t('Order.totalBuyPrice') }} : </span>
                                    <span class="font-bold text-dark padding-right">${{
                                        orderDetail.totalUnitPrice.toLocaleString()
                                    }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-lg-4 col-12 mt-2">
                                    <div class="contensss d-flex justify-content-between bordered rounded">
                                        <span class="font-bold">{{ $t('Order.orderBookingNumber') }} : </span>
                                        <span class="mx-2">{{ orderDetail.bookingNo }}</span>
                                    </div>
                                </div>
                                <div class="col col-lg-4 col-12 mt-2">
                                    <div class="contensss d-flex justify-content-between bordered rounded">
                                        <span class="font-bold">{{ $t('Order.containers') }} : </span>
                                        <span class="mx-2">{{ orderDetail.containers }}</span>
                                    </div>
                                </div>
                                <div class="col col-lg-4 col-12 mt-2">
                                    <div class="contensss d-flex justify-content-between bordered rounded">
                                        <span class="font-bold">{{ $t('Order.Date') }} : </span>
                                        <span class="mx-2">{{ cleanDate(orderDetail.creationDate) }}</span>
                                    </div>
                                </div>
                                <div class="col col-lg-4 col-12 mt-2">
                                    <div class="contensss d-flex justify-content-between bordered rounded">
                                        <span class="font-bold">{{ $t('Order.tones') }} : </span>
                                        <span class="mx-2">{{ orderDetail.tones }}</span>
                                    </div>
                                </div>
                                <div class="col col-lg-4 col-12 mt-2">
                                    <div class="contensss d-flex justify-content-between bordered rounded">
                                        <span class="font-bold">{{ $t('Order.buyPrice') }} : </span>
                                        <span class="mx-2">{{ getCurrency(orderDetail.buyPriceCurrency) }}{{
                                            orderDetail.buyPrice.toLocaleString() }}</span>
                                    </div>
                                </div>
                                <div class="col col-lg-4 col-12 mt-2">
                                    <div class="contensss d-flex justify-content-between bordered rounded">
                                        <span class="font-bold">{{ $t('Order.totalBuyPrice') }} : </span>
                                        <span class="mx-2">{{ getCurrency(orderDetail.buyPriceCurrency) }}{{
                                            orderDetail.totalUnitPrice.toLocaleString() }}</span>
                                    </div>
                                </div>

                            </div>
                            <hr>
                            <div class="col col-lg-4">
                                <div class="d-flex justify-content-between">
                                    <span class="badge bg-danger p-2">{{
                                        $t('Order.expense') }} :
                                    </span> <span class="font-bold text-dark padding-right">${{
                                        totalExpenses.toLocaleString() }}</span>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col col-lg-4 col-12 mt-2" v-for="(expense) in orderDetail.orderExpenses"
                                    :key="'expenses-' + expense.id">
                                    <div class="contensss bordered rounded">
                                        <div class="parent-detail d-flex justify-content-between">
                                            <span class="font-bold">{{ expense.description }} : </span>
                                            <span class="mx-2">${{ expense.afterExchange.toLocaleString() }}</span>
                                        </div>
                                        <hr>
                                        <div class="content-small-details col-12 mt-1">
                                            <div class="moreDetail d-flex justify-content-between mt-1">
                                                <span class="font-bold">{{ $t('OrderExpense.creationDate') }} : </span>
                                                <span class="mx-2">{{ cleanDate(expense.creationDate) }}</span>
                                            </div>
                                            <div class="moreDetail d-flex justify-content-between mt-1">
                                                <span class="font-bold">{{ $t('OrderExpense.amount') }} : </span>
                                                <span class="mx-2">¥{{ expense.amount.toLocaleString() }}</span>
                                            </div>
                                            <div class="moreDetail d-flex justify-content-between mt-1">
                                                <span class="font-bold ">{{ $t('OrderExpense.exchangeRate') }} : </span>
                                                <span class="mx-2">¥{{ expense.exchangeRate.toLocaleString() }}</span>
                                            </div>
                                            <div class="moreDetail d-flex justify-content-between mt-1">
                                                <span class="font-bold ">{{ $t('OrderExpense.afterExchange') }} : </span>
                                                <span class="mx-2">${{ expense.afterExchange.toLocaleString() }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col col-lg-4">
                                <div class="d-flex justify-content-between">
                                    <span class="badge bg-success p-2">{{
                                        $t('OrderContainer.totalSellPrice') }} : </span>
                                    <span class="font-bold text-dark padding-right">${{
                                        totalSellings.toLocaleString() }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col col-lg-4 col-12 mt-2" v-for="(container) in orderDetail.orderContainers"
                                    :key="'container-' + container.id">
                                    <div class="contensss bordered rounded">
                                        <div class="parent-detail d-flex justify-content-between">
                                            <span class="font-bold">{{ container.description }} : </span>
                                            <span class="mx-2">${{ container.totalSellPrice.toLocaleString() }}</span>
                                        </div>
                                        <hr>
                                        <div class="content-small-details col-12 mt-1">
                                            <div class="moreDetail d-flex justify-content-between mt-1">
                                                <span class="font-bold">{{ $t('OrderExpense.creationDate') }} : </span>
                                                <span class="mx-2">{{ cleanDate(container.creationDate) }}</span>
                                            </div>
                                            <div class="moreDetail d-flex justify-content-between mt-1">
                                                <span class="font-bold">{{ $t('Order.tones') }} : </span>
                                                <span class="mx-2">{{ container.tones.toLocaleString() }}</span>
                                            </div>
                                            <div class="moreDetail d-flex justify-content-between mt-1">
                                                <span class="font-bold ">{{ $t('Order.sellPrice') }} : </span>
                                                <span class="mx-2">${{ container.sellPrice.toLocaleString() }}</span>
                                            </div>
                                            <div class="moreDetail d-flex justify-content-between mt-1">
                                                <span class="font-bold ">{{ $t('Order.totalSell') }} : </span>
                                                <span class="mx-2">${{ container.totalSellPrice.toLocaleString() }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col col-lg-4">
                                <div class="d-flex justify-content-between">
                                    <span class="badge bg-warning text-dark p-2">{{
                                        $t('Attachments') }} : </span>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col col-lg-4 col-12 mt-2" v-for="(attachment) in orderDetail.attachments"
                                    :key="'attachment-' + attachment.id">
                                    <div class="contensss bordered rounded" style="height: 100%;">
                                        <div class="parent-detail d-flex justify-content-center">
                                            <span class="font-bold">{{ attachment.description }} </span>

                                        </div>
                                        <hr>
                                        <div class="content-small-details col-12 mt-1 d-flex justify-content-center ">
                                            <div class="moreDetail mt-1 d-flex justify-content-center align-items-center">
                                                <a v-if="attachment.imageLink.split('.')[1] === 'pdf'"
                                                    @click="selectedAttachment = attachment" role="button"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                    <i class="fa fa-file-pdf text-danger text-center d-block m-auto "
                                                        style="font-size: 40px;"></i>
                                                </a>

                                                <a v-else @click="selectedAttachment = attachment" role="button"
                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                >
                                                    <img class="d-block" :src="$hostname + attachment.imageLink" alt=""
                                                        style="width: 100px; height:auto">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row justify-content-center mt-2">
                                <div class="col col-lg-4 col-12 ">
                                    <div
                                        class="contensss d-flex justify-content-between bordered rounded border-primary text-primary">
                                        <span class="font-bold">{{ $t('Order.totalBuyPrice') }} : </span>
                                        <span class="font-bold mx-2">${{ orderDetail.totalUnitPrice.toLocaleString()
                                        }}</span>
                                    </div>
                                </div>

                            </div>
                            <div class="row justify-content-center mt-2">
                                <div class="col col-lg-4 col-12 ">
                                    <div
                                        class="contensss d-flex justify-content-between bordered rounded border-danger text-danger">
                                        <span class="font-bold">{{ $t('Order.expense') }} : </span>
                                        <span class="font-bold mx-2">${{ totalExpenses.toLocaleString() }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center mt-2">
                                <div class="col col-lg-4 col-12 ">
                                    <div
                                        class="contensss d-flex justify-content-between bordered rounded border-success text-success">
                                        <span class="font-bold">{{ $t('OrderContainer.totalSellPrice') }} : </span>
                                        <span class="font-bold mx-2">${{ totalSellings.toLocaleString() }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center mt-2">
                                <div class="col col-lg-4 col-12 ">
                                    <div class="contensss d-flex justify-content-between bordered rounded "
                                        :class="(totalSellings) - (orderDetail.totalUnitPrice + totalExpenses) < 0 ? 'border-danger text-danger' : 'border-success text-success'">
                                        <span class="font-bold">{{ $t('result') }} : </span>
                                        <span class="font-bold mx-2">${{ ((totalSellings) - (orderDetail.totalUnitPrice +
                                            totalExpenses)).toLocaleString() }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { get } from '@/api'
import AttachmentModal from '@/components/AttachmentModal'
export default {
    name: "OrderDetail",
    components: {
        AttachmentModal
    },
    data() {
        return {
            orderDetail: null,
            totalExpenses: 0.0,
            totalSellings: 0.0,
            selectedAttachment: null,
        }
    },
    methods: {
        async loadOrderDetails() {
            this.$loading(true);
            const URL = `/api/Orders/detail?orderId=${this.$route.params.orderId}`
            try {
                this.orderDetail = await get(URL)
                if (this.orderDetail) {
                    this.totalExpenses = this.orderDetail.orderExpenses.reduce((total, expense) => total + expense.afterExchange, 0);
                    this.totalSellings = this.orderDetail.orderContainers.reduce((total, container) => total + container.totalSellPrice, 0);
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.$loading(false);
            }

        },
    },
    async mounted() {
        this.loadOrderDetails();
    },
}
</script>