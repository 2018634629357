<style scoped>

</style>
<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("attachments.title") }}</h1>
      <AttachmentModal :selectedAttachment="selectedAttachment" />
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col col-lg-12 col-12">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="myVueDropzone"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="handleFileAdded"
                  >
                    <div class="dropzone">
                      <div class="dz-message">
                        {{ $t("dropFile") }}
                      </div>
                    </div>
                  </vue-dropzone>
                  <small class="text-danger d-block" v-if="files.length > 1"
                    >*{{ $t("uploadOne") }}</small
                  >
                  <button
                    v-if="fileDroped"
                    @click="removeFiles"
                    class="btn btn-danger mt-1"
                  >
                    {{ $t("removeFile") }}
                  </button>
                </div>
                <div class="col col-lg-12 col-12 mt-3">
                  <textarea
                    v-model="attachmentBody.description"
                    required
                    class="form-control"
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    :placeholder="'* ' + $t('writeAttachmentDes').toString()"
                  ></textarea>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col col-lg-2 col-12 mt-3">
                  <button
                    @click="addAttachment"
                    :disabled="files.length > 1"
                    class="btn btn-success w-lg-50 w-100"
                  >
                    {{ $t("add") }}
                  </button>
                </div>
              </div>

              <div class="row">
                <div
                  class="col col-lg-3 col-12 mt-3"
                  v-for="attachment in attachments"
                  :key="'a' + attachment.id"
                >
                  <div
                    class="main-attachment-card card shadow d-flex align-items-center"
                    v-if="attachment.imageLink"
                  >
                    <div class="attachment-del-container p-2 text-light rounded">
                      <button
                        @click="deleteRow(attachment.id)"
                        class="btn btn-outline-danger"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                    <div class="card-body p-1 mt-2">
                      <div class="contentss">
                        <span class="d-block">
                          <a
                            v-if="attachment.imageLink.split('.')[1] === 'pdf'"
                            role="button"
                            @click="selectedAttachment = attachment"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i
                              class="fa fa-file-pdf text-danger text-center d-block m-auto"
                              style="font-size: 40px"
                            ></i>
                          </a>

                          <a
                            v-else
                            @click="selectedAttachment = attachment"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <img
                              class="d-block"
                              :src="$hostname + attachment.imageLink"
                              alt=""
                              style="width: 100px; height: auto"
                            />
                          </a>
                          {{ attachment.description }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AttachmentModal from "@/components/AttachmentModal";
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { post, get, del } from "@/api";
export default {
  components: {
    VueDropzone,
    AttachmentModal,
  },
  data() {
    return {
      files: [],
      dropzoneOptions: {
        maxFiles: 1,
        url: "/upload",
        maxFilesize: 2,
        acceptedFiles: "image/*,.pdf",
      },
      formFile: new FormData(),
      fileDroped: null,

      attachmentBody: {
        attachmentType: parseInt(this.$route.params.type),
        entityId: parseInt(this.$route.params.entityId),
        description: null,
      },

      attachments: null,
      selectedAttachment: null,
    };
  },
  methods: {
    removeFiles() {
      this.$refs.myVueDropzone.removeAllFiles();
      this.fileDroped = null;
      this.files = [];
    },
    handleFileAdded(file) {
      this.fileDroped = null;
      this.formFile = new FormData();
      this.files.push(file);
      // Get the uploaded file data
      const uploadedFile = file;
      this.fileDroped = uploadedFile;
      // Create a new form file object

      this.formFile.append("image", uploadedFile, file.name);
    },

    resetProperties() {
      this.formFile = new FormData();
      this.attachmentBody = {
        attachmentType: parseInt(this.$route.params.type),
        entityId: parseInt(this.$route.params.entityId),
        description: null,
      };
    },
    async addAttachment() {
      if (!this.fileDroped) {
        this.$toast.open({
          message: this.$t("selectAFile"),
          type: "error",
        });
        return;
      }
      if (!this.attachmentBody.description) {
        this.$toast.open({
          message: this.$t("writeDescription"),
          type: "error",
        });
        return;
      }
      this.$loading(true);
      for (const key in this.attachmentBody) {
        if (key !== "image") {
          this.formFile.append(key, this.attachmentBody[key]);
        }
      }
      try {
        var URL = "/api/attachments";
        await post(URL, this.formFile);
        this.resetProperties();
        this.removeFiles();
        this.getAttachments();
        this.$toast.open({
          message: this.$t("newAttchment"),
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: "An Error Occured",
          type: "error",
        });
      } finally {
        this.$loading(false);
      }
    },
    async getAttachments() {
      this.$loading(true);
      try {
        var URL = `/api/attachments?entityId=${this.$route.params.entityId}&type=${this.$route.params.type}`;
        this.attachments = await get(URL, this.formFile);
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: "An Error Occured",
          type: "error",
        });
      } finally {
        this.$loading(false);
      }
    },
    async deleteRow(id) {
      this.$confirm(this.$t("messages.deleteConfirmation"), "Question", "question")
        .then(async () => {
          this.$loading(true);
          const URL = `/API/attachments?id=${id}`;
          try {
            await del(URL);
            await this.getAttachments();
            this.$toast.open({
              message: this.$t("messages.fileDeleted"),
              type: "success",
            });
          } catch (error) {
            console.log(error);
            this.$toast.open({
              message: this.$t("messages.error"),
              type: "error",
            });
          } finally {
            this.$loading(false);
          }
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
  },
  async mounted() {
    await this.getAttachments();
  },
};
</script>

<style>
.dropzone {
  border: 2px dashed #999;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #999;
  cursor: pointer;
}
</style>
