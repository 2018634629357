<style>
.accordion-button::after {
  display: none;
}

.text-grey {
  color: #b7babe !important;
}
</style>

<template>
  <div>
    <div>
      <nav id="sidebar" class="sidebar js-sidebar">
        <div class="sidebar-content js-simplebar">
          <a class="sidebar-brand" href="index.html">
            <span class="align-middle">Mini ERP</span>
          </a>
          <ul class="sidebar-nav">
            <li
              class="sidebar-item"
              :class="link.code == $route.name ? 'active' : ''"
              v-for="link in menuItems"
              :key="link.id"
            >
              <router-link class="sidebar-link" :to="link.route">
                <i class="align-middle" :class="link.icon"> </i>
                <span class="align-middle">{{ link.title }}</span>
              </router-link>
            </li>
            <li class="sidebar-item">
              <div
                class="accordion accordion-flush mx-3"
                id="accordionFlushExample"
                style="background-color: #222e3c"
              >
                <div class="accordion-item" style="background-color: #222e3c">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      style="background-color: #222e3c; color: rgba(233, 236, 239, 0.5)"
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <div style="margin-left: -9px; font-size:15px">
                        <i class="fa fa-money-bill-wheat"></i> <span class="mx-2">{{ $t("menu.expenses") }}</span>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body p-0">
                      <ul class="my-3">
                        <li class="text-light">
                          <router-link
                            class="dropdown-item-acc"
                            to="/CompanyExpenseTypes"
                            :class="
                              $route.name == 'CompanyExpenseType'
                                ? 'text-light'
                                : 'text-grey'
                            "
                            >{{ $t("menu.expenseTypes") }}</router-link
                          >
                        </li>
                        <li class="text-light my-2">
                          <router-link
                            class="dropdown-item-acc"
                            to="/Expenses/Daily"
                            :class="
                              $route.name == 'DailyExpenses' ? 'text-light' : 'text-grey'
                            "
                            >{{ $t("menu.dailyExpenses") }}</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="sidebar-item" >
              <div
                class="accordion accordion-flush mx-3"
                id="accordionFlushExampleReports"
                style="background-color: #222e3c"
              >
                <div class="accordion-item" style="background-color: #222e3c">
                  <h2 class="accordion-header" id="flush-headingOne" >
                    <button
                      style="background-color: #222e3c; color: rgba(233, 236, 239, 0.5)"
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOneReports"
                      aria-expanded="false"
                      aria-controls="flush-collapseOneReports"
                    >
                      <div style="margin-left: -9px; font-size:15px">
                        <i class="fa fa-file"></i> <span class="mx-2">{{ $t("menu.Reports") }}</span>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOneReports"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExampleReports"
                  >
                    <div class="accordion-body p-0">
                      <ul class="my-3">
                        <li class="text-light">
                          <router-link
                          
                            class="dropdown-item-acc"
                            to="/Reports/DailyTransactions"
                            :class="
                              $route.name == 'DailyTransactions'
                                ? 'text-light'
                                : 'text-grey'
                            "
                            >{{ $t("menu.DailyTransactions") }}</router-link
                          >
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: "SideNav",

  data() {
    return {
      menuItems: [
        {
          id: 1,
          code: "home",
          title: this.$t("menu.home"),
          icon: "fa fa-house",
          route: "/",
        },
        {
          id: 3,
          code: "CompaniesList",
          title: this.$t("menu.CompaniesList"),
          icon: "fa fa-building",
          route: "/Companies/List",
        },
        {
          id: 4,
          code: "WarehouseList",
          title: this.$t("menu.WarehouseList"),
          icon: "fa fa-warehouse",
          route: "/WarehouseList/List",
        },
        {
          id: 5,
          code: "BuyerCompaniesList",
          title: this.$t("menu.BuyerCompaniesList"),
          icon: "fa fa-building",
          route: "/BuyerCompanies/List",
        },
        {
          id: 6,
          code: "ItemsList",
          title: this.$t("menu.ItemsList"),
          icon: "fa fa-sitemap",
          route: "/Items/List",
        },
        {
          id: 7,
          code: "Orders",
          title: this.$t("menu.Orders"),
          icon: "fa fa-truck",
          route: "/Orders/List",
        },
        {
          id: 8,
          code: "SalesList",
          title: this.$t("menu.SalesList"),
          icon: "fa fa-cart-shopping",
          route: "/Sales/List",
        },
        {
          id: 9,
          code: "PaymentsList",
          title: this.$t("menu.PaymentsList"),
          icon: "fa fa-money-bill",
          route: "/Payments/List",
        },
        {
          id: 10,
          code: "SettingView",
          title: this.$t("menu.SettingView"),
          icon: "fa fa-gear",
          route: "/Setting/language",
        },
      ],
    };
  },
  watch: {
    "$i18n.locale"(newLang) {
      this.updateMenuItems(newLang);
    },
  },
  methods: {
    updateMenuItems(lang) {
      this.menuItems.forEach((item) => {
        item.title = this.$t(`menu.${item.code}`, { locale: lang });
      });
    },
  },
  mounted() {
    this.updateMenuItems(this.selectedLang);
  },
};
</script>
