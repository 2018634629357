import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import OrdersView from '@/views/Orders/OrdersView.vue'
import CreateOrderView from '@/views/Orders/CreateOrderView.vue'
import CreateOrderContainer from '@/views/OrderContainers/CreateOrderContainer.vue'
import CreateOrderExpense from '@/views/OrderExpenses/CreateOrderExpenseView.vue'
import CreateAttachment from '@/views/Attachments/CreateAttachment.vue'
import OrderReportView from '@/views/OrderReport/OrderReportView.vue'
import OrderDetailsView from '@/views/OrderDetails/Index.vue'
import OrderPaidsView from '@/views/OrderPaids/Index.vue'

import CompaniesList from '@/views/Companies/CompaniesList.vue'

import BuyerCompaniesList from '@/views/BuyerCompanies/Index.vue'
import BuyerCompanyProfile from '@/views/BuyerCompanies/ShowProfile.vue'

import ItemsList from '@/views/Items/Index.vue'

import CommercialInvoices from '@/views/OrderCommercialInvoices/Index.vue'
import CommercialInvoiceDetails from '@/views/CommercialInvoicesDetails/Index.vue'

import CreateSale from '@/views/Sales/CreateSale.vue'
import SalesList from '@/views/Sales/SalesList.vue'

import WarehouseList from '@/views/Warehouses/Index.vue'
import SingleWarehouse from '@/views/Warehouses/SingleWarehouse.vue'

import CreatePayment from '@/views/Payments/CreatePayment.vue'
import PaymentsList from '@/views/Payments/PaymentsList.vue'

import SettingView from '@/views/Settings/Index.vue'

import CompanyExpenseType from '@/views/CompanyExpenses/ExpenseTypesList.vue'
import DailyExpenses from '@/views/CompanyExpenses/ExpensesList.vue'

import DailyTransactions from "@/views/Reports/DailyTransactionList.vue"

import OrderProfit from "@/views/OrderProfits/Index.vue"

import InvoicePrint from "@/views/Prints/Invoice.vue"
import SalePayment from "@/views/SalesPayment/Index.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/Orders/List',
    name: 'Orders',
    component: OrdersView
  },
  {
    path: '/Orders/New',
    name: 'CreateOrder',
    component: CreateOrderView,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/OrderContainers/Create/:orderId',
    name: 'OrderContainer',
    component: CreateOrderContainer,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/OrderExpenses/Create/:orderId',
    name: 'OrderExpense',
    component: CreateOrderExpense,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Attachments/Create/:type/:entityId',
    name: 'CreateAttachment',
    component: CreateAttachment,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Orders/Report/:orderId',
    name: 'OrderReport',
    component: OrderReportView,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Orders/Details/:orderId',
    name: 'OrderDetails',
    component: OrderDetailsView,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Orders/Paids/:orderId/:companyName/:companyId/:totalAmount',
    name: 'OrderPaids',
    component: OrderPaidsView,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Companies/List',
    name: 'CompaniesList',
    component: CompaniesList,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },

  {
    path: '/WarehouseList/List',
    name: 'WarehouseList',
    component: WarehouseList,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/WarehouseList/Single/:name/:id',
    name: 'SingleWarehouse',
    component: SingleWarehouse,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/BuyerCompanies/List',
    name: 'BuyerCompaniesList',
    component: BuyerCompaniesList,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/BuyerCompanies/Profile/:name/:id',
    name: 'BuyerCompanyProfile',
    component: BuyerCompanyProfile,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Items/List',
    name: 'ItemsList',
    component: ItemsList,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/CommercialInvoices/List/:orderId',
    name: 'CommercialInvoices',
    component: CommercialInvoices,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/CommercialInvoiceDetails/List/:invoiceId/:orderId',
    name: 'CommercialInvoiceDetails',
    component: CommercialInvoiceDetails,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Sales/Create/:id?',
    name: 'CreateSale',
    component: CreateSale,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Sales/list',
    name: 'SalesList',
    component: SalesList,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Sales/Payments/:saleId/:companyId/:currencyType/:companyName/:totalAmount/:saleNo',
    name: 'SalePayment',
    component: SalePayment,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Payments/Create/:id?',
    name: 'CreatePayment',
    component: CreatePayment,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Payments/List',
    name: 'PaymentsList',
    component: PaymentsList,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },

  {
    path: '/Setting/language',
    name: 'SettingView',
    component: SettingView,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/CompanyExpenseTypes',
    name: 'CompanyExpenseType',
    component: CompanyExpenseType,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Expenses/Daily',
    name: 'DailyExpenses',
    component: DailyExpenses,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },

  {
    path: '/Reports/DailyTransactions',
    name: 'DailyTransactions',
    component: DailyTransactions,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    }
  },
  {
    path: '/Orders/OrderProfit/:purchaseNo/:orderId',
    name: 'OrderProfit',
    component: OrderProfit,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    },

  },
  {
    path: '/Prints/Invoice/:id',
    name: 'InvoicePrint',
    component: InvoicePrint,
    meta: {
      requiresAuth: true // add a meta field to indicate that this route requires authentication
    },

  }

]

const router = new VueRouter({
  routes
})
// register a global beforeEach guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('user')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        name: 'Login', // redirect to the login page if not authenticated
        query: { redirect: to.fullPath } // pass the current route path as a query parameter to redirect back after authentication
      })
    } else {
      next() // proceed to the next route
    }
  } else {
    next() // proceed to the next route
  }
})
export default router
