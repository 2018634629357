<template>
  <div>
    <div class="container-fluid p-0">
      <CreateAttachmentModal
        type="6"
        :entity-id="selectedData"
        :onAttachmentCreation="handleAfterAddAttachment"
      />
      <AttachmentModal :selected-attachment="selectedAttachment" />
      <h1 class="h3 mb-3">{{ $t("commercialInvoicesAndPackingList") }}</h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end my-2">
                <button
                  class="btn btn-success btn-sm rounded mx-1"
                  @click="addToDetail()"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#CreateAttachmentModal"
                  class="btn btn-warning btn-sm rounded text-dark mx-1 d-none"
                  id="open-attachment-modal"
                >
                  {{ $t("newAttachment") }}
                </button>
              </div>
              <div class="table-responsive" v-if="details && details.length > 0">
                <div class="table-container" style="overflow-x: auto">
                  <table class="table">
                    <thead>
                      <tr class="table-head-color text-light text-center">
                        <td>#</td>
                        <td>{{ $t("commercialInvoice.BookingNo") }}</td>
                        <td>
                          {{ $t("commercialInvoice.commercialInvoice") }}
                        </td>
                        <td>
                          {{ $t("commercialInvoice.arrivedToWarehouse") }}
                        </td>
                        <td>
                          {{ $t("commercialInvoice.warehouse") }}
                        </td>
                        <td>
                          {{ $t("commercialInvoice.attachment") }}
                        </td>
                        <td>
                          {{ $t("commercialInvoice.note") }}
                        </td>
                       
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in details"
                        :key="index"
                        class="text-center"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          <div class="d-flex justify-content-center">
                            <input
                              style="width: 250px"
                              class="form-control"
                              :placeholder="$t('commercialInvoice.BookingNo')"
                              v-model="item.bookingNo"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center">
                            <input
                              style="width: 250px"
                              class="form-control"
                              :placeholder="$t('commercialInvoice.commercialInvoice')"
                              v-model="item.commercialInvoice"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            class="form-check d-flex justify-content-center"
                            v-if="item.id > 0"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="item.arrived"
                              id="flexCheckDefault"
                              @change="changeArriveStatus(item.id)"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            class="form-check d-flex justify-content-center"
                            v-if="warehouses && warehouses.items && item.arrived"
                          >
                            <select
                              style="width: 200px"
                              class="form-control"
                              v-model="item.warehouseId"
                            >
                              <option
                                :value="warehouse.id"
                                v-for="warehouse in warehouses.items"
                                :key="'warehouse-' + warehouse.id"
                              >
                                {{ warehouse.name }}
                              </option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <button
                          @click="openAttachmentModel(item)"
                          v-if="!item.attachment"
                          class="btn btn-warning text-dark rounded btn-sm"
                        >
                          {{ $t("Upload") }}
                        </button>
                        <div v-else>
                          <button
                            class="btn btn-primary rounded btn-sm d-block"
                            @click="viewAttachment(item.attachment)"
                          >
                            {{ $t("ViewAttachment") }}
                          </button>
                          <button
                            class="btn btn-danger rounded btn-sm d-block mt-1"
                            @click="deleteAttachment(item.attachment.id)"
                          >
                            {{ $t("DeleteAttachment") }}
                          </button>
                        </div>
                        </td>
                        <td>
                          <textarea
                            v-model="item.note"
                            style="width: 200px"
                            class="form-control"
                          ></textarea>
                        </td>
                        <td>
                          <button
                            class="btn btn-danger btn-sm rounded my-1 mx-1"
                            @click="removeItem(index)"
                            v-if="item.id == 0"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                          <button
                            class="btn btn-danger btn-sm rounded my-1 mx-1"
                            @click="deleteDetail(item.id)"
                            v-else
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                          <router-link :to="`/CommercialInvoiceDetails/List/${item.id}/${$route.params.orderId}`" class="btn btn-primary my-1 mx-1" v-if="item.id > 0">{{ $t('details') }}</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="row justify-content-end my-3"
                v-if="details && details.length > 0"
              >
                <div class="col col-lg-1 col-12 d-flex justify-content-end">
                  <button class="btn btn-success rounded" @click="saveDetails()">
                    {{ $t("purchase.save") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get, put, del } from "@/api";
import CreateAttachmentModal from "@/components/CreateAttachmentModal.vue";
import AttachmentModal from "@/components/AttachmentModal.vue";
export default {
  name: "CommercialInvoices",
  components: {
    CreateAttachmentModal,
    AttachmentModal,
  },
  data() {
    return {
      detailBody: {
        id: 0,
        commercialInvoice: null,
        bookingNo: null,
        warehouseId: 0,
        warehouseName: null,
        arrived: false,
        orderId: parseInt(this.$route.params.orderId),
        note: null,
      },
      details: [],
      warehouses: null,

      selectedData: "0",
      selectedAttachment: null,
    };
  },
  methods: {
    async handleAfterAddAttachment() {
      await this.getDetails();
    },
    openAttachmentModel(data) {
      this.selectedData = data.id.toString();
      this.openModal("CreateAttachmentModal");
    },
    viewAttachment(data) {
      this.selectedAttachment = data;
      this.openModal("ViewAttachmentModal");
    },
    deleteAttachment(id) {
      this.$confirm(this.$t("messages.deleteConfirmation"), "Question", "question")
        .then(async () => {
          this.$loading(true);
          const URL = `/API/attachments?id=${id}`;
          await del(URL);
          await this.getDetails();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    resetProperties() {
      this.detailBody = {
        id: 0,
        commercialInvoice: null,
        bookingNo: null,
        warehouseId: 0,
        warehouseName: null,
        arrived: false,
        orderId: parseInt(this.$route.params.orderId),
      };
    },
    addToDetail() {
      this.details.push(this.detailBody);
      this.resetProperties();
    },
    async saveDetails() {
      this.$loading(true);
      const URL = "/api/orderCommercialInvoices";
      await post(URL, this.details);
      await this.getDetails();
      this.$loading(false);
    },
    async changeArriveStatus(id) {
      this.$loading(true);
      const URL = `/api/orderCommercialInvoices/changeArrival?id=${id}`;
      await put(URL);
      await this.getDetails();
      this.$loading(false);
    },
    async deleteDetail(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/orderCommercialInvoices?id=${id}`;
          await del(URL);
          await this.getDetails();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    removeItem(index) {
      this.details.splice(index, 1);
    },
    async getDetails() {
      this.$loading(true);
      const URL = `/api/orderCommercialInvoices?orderId=${this.$route.params.orderId}`;
      this.details = await get(URL);
      this.$loading(false);
    },
    async loadWarehouses() {
      this.$loading(true);
      const URL = "/api/warehouses";
      this.warehouses = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.getDetails();
    await this.loadWarehouses();
  },
};
</script>
