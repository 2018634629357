<template>
  <div>
    <div class="col col-lg-3 col-12 form-group my-2">
      <label for="">{{ $t("purchase.productType") }}</label>
      <select
        @change="loadOrderdItems()"
        class="form-control mt-1"
        v-model="selectedProductType"
      >
        <option value="Glass">{{ $t("Glass") }}</option>
        <option value="Raw Material ">{{ $t("RawMaterial") }}</option>
      </select>
    </div>
    <div class="table-responsive rounded" v-if="orders">
      <table class="table table-hover my-0">
        <thead style="background-color: #222e3c">
          <tr class="text-light text-center">
            <th>#</th>
            <th>{{ $t("orderedItems.date") }}</th>
            <th>{{ $t("orderedItems.purchaseNo") }}</th>
            <th>{{ $t("orderedItems.commercialInvoice") }}</th>
            <th>{{ $t("orderedItems.bookingNo") }}</th>
            <th>{{ $t("orderedItems.containerNo") }}</th>
            <th>{{ $t("orderedItems.itemName") }}</th>
            <th v-if="checkGlass()">{{ $t("orderedItems.quality") }}</th>
            <th v-if="checkGlass()">{{ $t("orderedItems.thickness") }}</th>
            <th v-if="checkGlass()">{{ $t("orderedItems.dimension") }}</th>
            <th v-if="checkGlass()">{{ $t("orderedItems.creates") }}</th>
            <th>
              {{
                checkGlass() ? $t("orderedItems.sheetsPerCrate") : $t("orderedItems.ton")
              }}
            </th>
            <th>
              {{
                checkGlass() ? $t("orderedItems.pricePerSQM") : $t("orderedItems.tone")
              }}
            </th>
            <th v-if="checkGlass()">{{ $t("orderedItems.totalSQM") }}</th>
            <th>{{ $t("orderedItems.totalAmount") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in orders.items"
            :key="'item-' + index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ cleanDate(item.creationDate) }}</td>
            <td>{{ item.purchaseNO }}</td>
            <td>{{ item.commercialInvoice }}</td>
            <td>{{ item.bookingNumber }}</td>
            <td>{{ item.containerNo }}</td>
            <td>{{ item.itemName }}</td>
            <td v-if="checkGlass()">{{ item.quality }}</td>
            <td v-if="checkGlass()">{{ item.thinkness }}mm</td>
            <td v-if="checkGlass()">{{ item.dimensionW }} X {{ item.dimensionH }}</td>
            <td v-if="checkGlass()">{{ item.numberOfCrate }}</td>
            <td>{{ checkGlass() ? item.sheetsPerCrate : item.numberOfTones }}</td>
            <td>
              {{ getCurrency(item.currencyType) }}
              {{
                checkGlass()
                  ? item.pricePerSQM.toLocaleString()
                  : item.pricePerTone.toLocaleString()
              }}
            </td>
            <td v-if="checkGlass()">
              {{ item.totalSQMPerContainer.toLocaleString() }}
            </td>
            <td>
              {{ getCurrency(item.currencyType) }} {{ item.totalAmount.toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :pageIndex="pageIndex"
      :totalPages="totalPages"
      :onPageChange="handlePageChange"
    />
  </div>
</template>
<script>
import { get } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
export default {
  name: "OrderedItemsTab",
  components: {
    Pagination,
  },
  data() {
    return {
      selectedProductType: "Glass",
      orders: null,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  computed: {
    totalItems() {
      return this.orders ? this.orders.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.orders.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    checkGlass() {
      return this.selectedProductType == "Glass";
    },
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadOrderdItems();
    },
    async loadOrderdItems() {
      this.$loading(true);
      const URL = `/api/WarehouseReports/GetSpecificWarehouseItemOrdersTab?warehouseId=${this.$route.params.id}&page=${this.pageIndex}&size=${this.pageSize}&productType=${this.selectedProductType}`;
      this.orders = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadOrderdItems();
  },
};
</script>
