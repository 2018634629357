<style scoped>
.active-mode {
  background-color: rgb(34, 46, 60);
  color: white;
  border-radius: 5px;
}
</style>
<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ this.$route.params.name }}</h1>

      <div class="row" v-if="companyInfo">
        <div class="col-lg-4 col-12">
          <div class="card" style="background-color: #ad443d;"> 
            <div class="card-body justify-content-between d-flex">
              <span class="text-light">{{ $t("buyerCompanies.totalDebtDollar") }}</span>
              <span class="text-light">{{ companyInfo.totalOweDollar.toLocaleString()  }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="card" style="background-color: #ad443d;"> 
            <div class="card-body justify-content-between d-flex">
              <span class="text-light">{{ $t("buyerCompanies.totalDebtYuan") }}</span>
              <span class="text-light">{{ companyInfo.totalOweYuan.toLocaleString()  }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ul class="nav">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click="mode = 1"
                    :class="mode == 1 ? 'active-mode' : ''"
                    >{{ $t("mode.sales") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click="mode = 2"
                    :class="mode == 2 ? 'active-mode' : ''"
                    >{{ $t("mode.payments") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card" v-if="mode == 1">
            <div class="card-body">
              <SalesTab/>
            </div>
          </div>
          <div class="card" v-if="mode == 2">
            <div class="card-body">
              <PaymentsTab/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {get} from "@/api"
import SalesTab from "@/components/BuyerCompanies.vue/SalesTab.vue";
import PaymentsTab from "@/components/BuyerCompanies.vue/PaymentsTab.vue";
export default {
  name: "CompanyProfile",
  data() {
    return {
      companyInfo: null,
      mode: 1
    };
  },
  components:{
    SalesTab,
    PaymentsTab
  },
  methods: {
    async loadCompanyInfo(){
      this.$loading(true)
      const URL = `/api/buyerCompanies/getSingle?id=${this.$route.params.id}`
      this.companyInfo = await get(URL)
      this.$loading(false)
    }
  },
  async mounted() {
    await this.loadCompanyInfo()
  },
};
</script>
