<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("items.Title") }}</h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <DataTable
                :table-config="category"
                :modal-create-title="$t('items.add')"
                :modal-update-title="$t('items.update')"
              ></DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/DataTable.vue";
export default {
  name: "ItemsView",
  components: {
    DataTable,
  },
  data() {
    return {
      showModal: false,
      category: {
        url: "/api/items",
        fields: [
          {
            title: this.$t("items.TitleSingle"),
            name: "name",
            type: "string",
            visible: true,
          },
          {
            title: this.$t("items.zhName"),
            name: "nameZh",
            type: "string",
            visible: true,
          },
          {
            title: this.$t("items.productType"),
            name: "productType",
            type: "select",
            selectValues: [
              { value: "Glass", text: "Glass" },
              { value: "Raw Material", text: "Raw Material" },
            ],
            visible: true,
          },
        ],
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>
