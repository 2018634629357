import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSimpleAlert from "vue-simple-alert";
import Axios from "axios";
import VueLoading from "vuejs-loading-plugin";
import VueToast from 'vue-toast-notification';
import loader from "./components/CustomeLoader"
import i18n from './i18n'
import mixin from "./generalMixin";
import Print from 'vue-print-nb'

import { hostname } from './api'

Vue.prototype.$hostname = hostname;

import VueMultiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"

Vue.prototype.$Axios = Axios;

import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, {
  position: 'top-right'
});
Vue.use(VueSimpleAlert);
Vue.use(VueLoading, {
  dark: true, // default false
  customLoader: loader,
  background: "rgba(255,255,255,0.5)" // set custom background
});
Vue.mixin({
  methods: {
    config: mixin.methods.config,
    cleanDate: mixin.methods.cleanDate,
    getCurrency: mixin.methods.getCurrency,
    formatDateAndAssignToInput: mixin.methods.formatDateAndAssignToInput,
    getTransactionClass: mixin.methods.getTransactionClass,
    openModal: mixin.methods.openModal,
    checkRootAdmin: mixin.methods.checkRootAdmin,
    checkAdmin: mixin.methods.checkAdmin,
    checkEmployee: mixin.methods.checkEmployee,
    getCurrentUserFullName: mixin.methods.getCurrentUserFullName,
  }
});
Vue.config.productionTip = false
Vue.component('vue-multiselect', VueMultiselect)
Vue.use(Print);
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
