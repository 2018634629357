<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">
        {{ $t("Order.OrderProfit") }} - {{ $route.params.purchaseNo }}
      </h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body" v-if="profit">
              <div class="col col-lg-6 col-12">
                <hr />
                <div class="content-db d-flex justify-content-between">
                  <h6>{{ $t("totalPurchase") }} :</h6>
                  <h6>
                    {{ getCurrency(profit.buyPriceCurrency) }}
                    {{ profit.totalPurchase.toLocaleString() }}
                  </h6>
                </div>
                <hr />

                <div class="content-db d-flex justify-content-between">
                  <h6>{{ $t("totalExpenseInDollar") }} :</h6>
                  <h6>
                    $
                    {{ profit.totalExpenseDollar.toLocaleString() }}
                  </h6>
                </div>
                <hr />
                <div class="content-db d-flex justify-content-between">
                  <h6>{{ $t("totalSold") }} :</h6>
                  <h6>
                    {{ getCurrency(profit.buyPriceCurrency) }}
                    {{ profit.totalSold.toLocaleString() }}
                  </h6>
                </div>
                <hr />
                <div class="content-db d-flex justify-content-between">
                  <h6>{{ $t("profit") }} :</h6>
                  <h6 v-if="profit.totalSold == 0">0</h6>
                  <h6
                    v-else
                    :class="
                      profit.totalSold -
                        (profit.totalPurchase + profit.totalExpenseDollar) >
                      0
                        ? 'text-success'
                        : 'text-danger'
                    "
                  >
                    {{ getCurrency(profit.buyPriceCurrency) }}
                    {{
                      (
                        profit.totalSold -
                        (profit.totalPurchase + profit.totalExpenseDollar)
                      ).toLocaleString()
                    }}
                  </h6>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "@/api";
export default {
  name: "OrderProfit",
  data() {
    return {
      profit: null,
    };
  },
  methods: {
    async loadPurchaseProfit() {
      this.$loading(true);
      const URL = `/api/orders/GetOrderProfit?orderId=${this.$route.params.orderId}`;
      this.profit = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadPurchaseProfit();
  },
};
</script>
