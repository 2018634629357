<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("payments.list") }}</h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex d-lg-flex justify-content-between my-2">
                <router-link class="btn btn-success" to="/Payments/Create">
                  {{ $t("OrderTbl.addNew") }}
                </router-link>
              </div>
              <div class="table-responsive rounded">
                <table class="table table-hover my-0">
                  <thead style="background-color: #222e3c">
                    <tr class="text-light text-center">
                      <th>{{ $t("Sale.Date") }}</th>
                      <th>{{ $t("Sale.ToCompany") }}</th>
                      <th>{{ $t("Sale.SaleNo") }}</th>
                      <th>{{ $t("Sale.paymentAmount") }}</th>
                      <th>{{ $t("Sale.note") }}</th>
                      <th>{{ $t("OrderTbl.actions") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="payments && payments.items">
                    <tr
                      class="text-center"
                      v-for="payment in payments.items"
                      :key="'payment-' + payment.id"
                    >
                      <td>{{ cleanDate(payment.creationDate) }}</td>
                      <td>
                        <router-link :to="`/BuyerCompanies/profile/${payment.entityName}/${payment.entityId}`">
                          {{ payment.entityName }}
                        </router-link>
                      </td>
                      <td>
                        <router-link :to="`/Sales/Create/${payment.saleId}`">{{
                          payment.saleNo
                        }}</router-link>
                       
                      </td>
                      <td>
                        {{ getCurrency(payment.currencyType) }}
                        {{ payment.amount.toLocaleString() }}
                      </td>
                      <td>
                        {{ payment.note }}
                      </td>

                      <td>
                        <button
                          @click="deletePayment(payment.id)"
                          v-if="payment.note != 'Initial Paid'"
                          type="button"
                          class="btn btn-danger btn-sm rounded mx-1 my-1"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                        <router-link
                          :to="`/payments/create/${payment.id}`"
                          v-if="payment.note != 'Initial Paid'"
                          role="button"
                          class="btn btn-primary btn-sm rounded mx-1 my-1"
                        >
                          <i class="fa fa-pen"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
              :pageIndex="pageIndex"
              :totalPages="totalPages"
              :onPageChange="handlePageChange"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, del } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
export default {
  name: "PaymentsList",
  components: {
    Pagination,
  },
  data() {
    return {
      payments: null,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  computed: {
    totalItems() {
      return this.payments ? this.payments.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.payments.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadPayments();
    },
    async loadPayments() {
      this.$loading(true);
      const URL = `/api/transactions/getpayments?page=${this.pageIndex}&size=${this.pageSize}`;
      this.payments = await get(URL);
      this.$loading(false);
    },
    async deletePayment(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/transactions/delete?id=${id}`;
          await del(URL);
          await this.loadPayments();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
  },
  async mounted() {
    await this.loadPayments();
  },
};
</script>
