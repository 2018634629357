<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("payments.create") }}</h1>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="row">{{ $t("payments.paymentFor") }}</th>
                      <td>
                        <vue-multiselect
                          v-model="paymentBody.entityId"
                          :options="companies"
                          placeholder="Select Company"
                          label="name"
                          track-by="id"
                        ></vue-multiselect>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("payments.saleNo") }}</th>
                      <td>
                        <vue-multiselect
                          v-model="paymentBody.saleId"
                          :options="companySales"
                          placeholder="Select a Sale"
                          label="saleNo"
                        ></vue-multiselect>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("payments.saleIssuedOn") }}</th>
                      <td>
                        <span v-if="saleInfo">
                          {{ cleanDate(saleInfo.creationDate) }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("payments.saleAmount") }}</th>
                      <td>
                        <span v-if="saleInfo">
                          {{ getCurrency(saleInfo.currencyType) }}
                          {{ saleInfo.totalAmount.toLocaleString() }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("payments.paymentDate") }}</th>
                      <td>
                        <input
                          type="date"
                          class="form-control wide-input"
                          v-model="paymentBody.creationDate"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("payments.paymentAmount") }}</th>
                      <td>
                        <input
                          type="number"
                          step="any"
                          class="form-control wide-input"
                          v-model="paymentBody.amount"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("payments.totalPaid") }}</th>
                      <td>
                        <span v-if="saleInfo">
                          {{ getCurrency(saleInfo.currencyType) }}
                          {{ saleInfo.totalPaid.toLocaleString() }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("payments.remaining") }}</th>
                      <td>
                        <span v-if="saleInfo">
                          {{ getCurrency(saleInfo.currencyType) }}
                          {{ saleInfo.remaining.toLocaleString() }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("payments.note") }}</th>
                      <td>
                        <textarea
                          v-model="paymentBody.note"
                          class="form-control"
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="btn btn-success" @click="savePayment()">
                {{ $t("paymentBody.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from "@/api";
export default {
  name: "CreatePayment",

  data() {
    return {
      companies: [],
      companySales: [],
      saleInfo: null,
      paymentBody: {
        id: 0,
        transactionType: 1,
        amount: 0,
        currencyType: 1,
        entityId: null,
        saleId: null,
        creationDate: new Date(),
        note: null,
      },
    };
  },
  watch: {
    async "paymentBody.entityId"(newVal) {
      if (newVal) {
        await this.loadCompanySales(newVal.id);
      }
    },
    "paymentBody.saleId"(newVal) {
      if (newVal) {
        this.saleInfo = newVal;
        this.paymentBody.saleId = newVal;
        this.paymentBody.currencyType = newVal.currencyType;
      }
    },
  },
  methods: {
    async savePayment() {
      if (!(this.paymentBody.entityId || this.paymentBody.saleId)) return;
      if (this.paymentBody.paymentAmount == 0) return;

      this.paymentBody.entityId = this.paymentBody.entityId.id;
      this.paymentBody.saleId = this.paymentBody.saleId.id;
      this.$loading(true);
      const URL = `/api/transactions`;
      await post(URL, this.paymentBody);
      this.$loading(false);
      this.$router.push("/Payments/List");
    },
    async loadCompanySales(companyId) {
      this.companySales = [];
      this.$loading(true);
      const URL = `/api/sales?companyId=${companyId}&excludePaids=true&page=1&size=1000000000`;
      const res = await get(URL);
      this.companySales = res.items;
      this.$loading(false);
    },
    async loadCompanies() {
      this.$loading(true);
      const URL = `/api/buyerCompanies?page=1&size=1000000`;
      const res = await get(URL);
      this.companies = res.items;
      this.$loading(false);
    },

    async loadSinglePayment() {
      this.$loading(true);
      const URL = `/api/transactions/getSinglePayment?id=${this.$route.params.id}`;
      const res = await get(URL);
      this.paymentBody = {
        id: res.id,
        transactionType: 1,
        amount: res.amount,
        currencyType: res.currencyType,
        entityId: { id: res.entityId, name: res.entityName },
        saleId: await this.loadSingleSale(res.saleId),
        creationDate: this.formatDateAndAssignToInput(res.creationDate),
        note: res.note,
      };

      this.$loading(false);
    },
    async loadSingleSale(saleId) {
      this.$loading(true);
      const URL = `/api/sales/GetSingle?saleId=${saleId}`;
      const res = await get(URL);
      this.$loading(false);
      return res
    },
  },
  async mounted() {
    await this.loadCompanies();
    if (this.$route.params.id) {
      await this.loadSinglePayment();
    }
  },
};
</script>
