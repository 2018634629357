<style scoped>
.totals span {
  font-weight: bold !important;
}
</style>
<template>
  <div>
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">{{ $t("paids.title") }}</h1>
      <CreateAttachmentModal
        type="5"
        :entity-id="selectedData"
        :onAttachmentCreation="handleAfterAddAttachment"
      />
      <AttachmentModal :selected-attachment="selectedAttachment" />
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("paids.AddOrUpdateOrderPaid") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form class="form" @submit.prevent="saveTransaction">
              <div class="modal-body">
                <div class="form-group mt-1">
                  <label for="">{{ $t("paids.companyName") }}</label>
                  <input
                    class="form-control mt-1"
                    type="text"
                    :value="$route.params.companyName"
                    disabled
                  />
                </div>
                <div class="form-group mt-1">
                  <label for="">{{ $t("expense.date") }}</label>
                  <input
                    class="form-control mt-1"
                    type="date"
                    v-model="transactionBody.creationDate"
                  />
                </div>
                <div class="form-group mt-1">
                  <label for="">{{ $t("expense.amount") }}</label>
                  <div class="row">
                    <div class="col-10">
                      <input
                        required
                        class="form-control mt-1"
                        type="number"
                        step="any"
                        v-model.number="transactionBody.amount"
                      />
                    </div>
                    <div class="col-2">
                      <select
                        required
                        class="form-control mt-1"
                        v-model.number="transactionBody.currencyType"
                      >
                        <option value="1">$</option>
                        <option value="2">¥</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-1" v-if="transactionBody.currencyType == 2">
                  <label for="">{{ $t("expense.exchangeRate") }}</label>
                  <input
                    type="number"
                    step="any"
                    class="form-control mt-1"
                    v-model="transactionBody.exchangeRate"
                  />
                </div>
                <div class="form-group mt-1" v-if="transactionBody.currencyType == 2">
                  <label for="">{{ $t("expense.afterExchange") }}</label>
                  <input
                    type="number"
                    step="any"
                    class="form-control mt-1"
                    disabled
                    :value="(transactionBody.amount / transactionBody.exchangeRate).toFixed(2)"
                  />
                </div>
                <div class="form-group mt-1">
                  <label for="">{{ $t("expense.detail") }}</label>
                  <input
                    type="text mt-1"
                    class="form-control"
                    v-model="transactionBody.note"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="close-btn"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {{ $t("purchase.close") }}
                </button>
                <button type="submit" class="btn btn-primary">
                  {{ $t("purchase.save") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="head d-flex justify-content-between align-items-end my-2">
                <button
                  style="height: 45px"
                  @click="resetProperties()"
                  class="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {{ $t("paids.newOrderPayment") }}
                </button>
                <div class="totals">
                  <hr />
                  <div class="d-flex justify-content-between">
                    <span>{{ $t("order.totalAmount") }} : </span>
                    <span class="mx-2">
                      $ {{ Number($route.params.totalAmount).toLocaleString() }}
                    </span>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <span>{{ $t("order.paids") }} : </span>
                    <span class="mx-2"> $ {{ (finalTotal).toLocaleString() }} </span>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <span>{{ $t("order.paidsYuan") }} : </span>
                    <span class="mx-2"> ¥ {{ (finalTotalYuan).toLocaleString() }} </span>  | {{ $t("changedToUSD") + finalTotalAfterExchange  }}
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <span>{{ $t("order.finalTotalPaid") }} : </span>
                    <span class="mx-2"> $ {{ (finalTotal + finalTotalAfterExchange).toLocaleString() }} </span>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <span>{{ $t("order.remaining") }} : </span>
                    <span class="mx-2">
                      $ {{ ($route.params.totalAmount - (finalTotal + finalTotalAfterExchange)).toLocaleString() }}
                    </span>
                  </div>
                  <hr />
                </div>
              </div>
              <div
                class="table-responsive rounded"
                v-if="paids && paids.items && paids.items.length > 0"
              >
                <table class="table table-hover my-0">
                  <thead style="background-color: #222e3c">
                    <tr class="text-light">
                      <th>#</th>
                      <th>{{ $t("expense.date") }}</th>
                      <th>{{ $t("expense.amount") }}</th>
                      <th>{{ $t("expense.attachment") }}</th>
                      <th>{{ $t("expense.note") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(paid, index) in paids.items" :key="'paid-' + paid.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ cleanDate(paid.creationDate) }}</td>

                      <td>
                        <div>
                          {{ getCurrency(paid.currencyType) }}
                        {{ paid.amount.toLocaleString() }}
                        </div>
                        <div v-if="paid.currencyType == 2"> $1 = ¥{{ paid.exchangeRate }}</div>
                        <div v-if="paid.currencyType == 2"> {{ $t("expense.afterExchange") }}: ${{ paid.afterExchange.toLocaleString() }}</div>
                      </td>
                      <td>
                        <button
                          @click="openAttachmentModel(paid)"
                          v-if="!paid.attachment"
                          class="btn btn-warning text-dark rounded btn-sm"
                        >
                          {{ $t("Upload") }}
                        </button>
                        <div v-else>
                          <button
                            class="btn btn-primary rounded btn-sm d-block"
                            @click="viewAttachment(paid.attachment)"
                          >
                            {{ $t("ViewAttachment") }}
                          </button>
                          <button
                            class="btn btn-danger rounded btn-sm d-block mt-1"
                            @click="deleteAttachment(paid.attachment.id)"
                          >
                            {{ $t("DeleteAttachment") }}
                          </button>
                        </div>
                      </td>
                      <td>{{ paid.note }}</td>
                      <td>
                        <button
                          class="btn btn-danger mx-1 my-1 btn-sm rounded"
                          @click="deleteRow(paid.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                        <button
                          class="btn btn-primary mx-1 my-1 btn-sm rounded"
                          @click="editRow(paid)"
                        >
                          <i class="fa fa-pen"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
                :pageIndex="pageIndex"
                :totalPages="totalPages"
                :onPageChange="handlePageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post, del } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
import CreateAttachmentModal from "@/components/CreateAttachmentModal.vue";
import AttachmentModal from "@/components/AttachmentModal.vue";
import $ from "jquery";
export default {
  name: "paidsList",
  data() {
    return {
      paids: null,

      selectedDate: this.formatDateAndAssignToInput(new Date()),

      transactionBody: {
        id: 0,
        transactionType: 3,
        exchangeRate: 0.0,
        afterExchange: 0.0,
        amount: 0,
        currencyType: 1,
        entityId: parseInt(this.$route.params.companyId),
        saleId: parseInt(this.$route.params.orderId),
        note: null,
        creationDate: new Date(),
      },

      pageIndex: 1,
      pageSize: 1000,

      selectedData: "0",
      selectedAttachment: null,
    };
  },
  components: {
    Pagination,
    CreateAttachmentModal,
    AttachmentModal,
  },
  computed: {
    totalItems() {
      return this.paids ? this.paids.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.paids.items.slice(startIndex, startIndex + this.pageSize);
    },
    finalTotal() {
      return this.paids && this.paids.items
        ? this.paids.items.filter(x=>x.currencyType == 1).reduce((total, paid) => total + Number(paid.amount), 0)
        : 0;
    },
    finalTotalYuan() {
      return this.paids && this.paids.items
        ? this.paids.items.filter(x=>x.currencyType == 2).reduce((total, paid) => total + Number(paid.amount), 0)
        : 0;
    },
    finalTotalAfterExchange() {
      return this.paids && this.paids.items
        ? this.paids.items.filter(x=>x.currencyType == 2).reduce((total, paid) => total + Number(paid.afterExchange), 0)
        : 0;
    },
  },

  methods: {
    openAttachmentModel(data) {
      this.selectedData = data.id.toString();
      this.openModal("CreateAttachmentModal");
    },
    viewAttachment(data) {
      this.selectedAttachment = data;
      this.openModal("ViewAttachmentModal");
    },
    deleteAttachment(id) {
      this.$confirm(this.$t("messages.deleteConfirmation"), "Question", "question")
        .then(async () => {
          this.$loading(true);
          const URL = `/API/attachments?id=${id}`;
          await del(URL);
          await this.loadTransactions();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    async handleAfterAddAttachment() {
      await this.loadTransactions();
    },
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadTransactions();
    },
    async deleteRow(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/transactions/Delete?id=${id}`;
          await del(URL, true);
          await this.loadTransactions();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    editRow(transaction) {
      this.transactionBody = {
        id: transaction.id,
        transactionType: 2,
        amount: transaction.amount,
        exchangeRate: transaction.exchangeRate,
        afterExchange: transaction.afterExchange,
        currencyType: transaction.currencyType,
        entityId: transaction.entityId,
        saleId: transaction.saleId,
        note: transaction.note,
        creationDate: this.formatDateAndAssignToInput(transaction.creationDate),
      };
      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"), {
        keyboard: false,
      });
      myModal.show();
    },
    resetProperties() {
      this.transactionBody = {
        id: 0,
        transactionType: 3,
        exchangeRate: 0.0,
        afterExchange: 0.0,
        amount: 0,
        currencyType: 1,
        entityId: parseInt(this.$route.params.companyId),
        saleId: parseInt(this.$route.params.orderId),
        note: null,
        creationDate: new Date(),
      };
    },
    async loadTransactions() {
      this.$loading(true);
      const URL = `/api/transactions/getpayments?type=3&saleId=${this.$route.params.orderId}&page=${this.pageIndex}&size=${this.pageSize}`;
      const res = await get(URL);
      this.paids = res;
      this.$loading(false);
    },

    async saveTransaction() {
      this.$loading(true);
      if (this.transactionBody.currencyType == 2) {
        this.transactionBody.afterExchange =
          this.transactionBody.amount / this.transactionBody.exchangeRate;
      } else {
        this.transactionBody.exchangeRate = 0;
        this.transactionBody.afterExchange = 0;
      }
      const URL = "/api/transactions";
      await post(URL, this.transactionBody);
      await this.resetProperties();
      await this.loadTransactions();
      $("#close-btn").click();
      this.$loading(false);
    },
  },
  async mounted() {
    await this.loadTransactions();
  },
};
</script>
